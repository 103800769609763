import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Spinner, Toast, ToastBody } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';
import Autocomplete from "./Autocomplete";
import FileHelper from './FileHelper';
import { IoTrashOutline } from 'react-icons/io5';
import { BsPlusCircleFill } from 'react-icons/bs';
import Modal from 'react-modal';

export class ExamPreTest extends Component {
    static displayName = ExamPreTest.name;

    constructor(props) {
        super(props);

        this.state = {
            examId: userFunctions.GuidEmpty(),
            studentId: userFunctions.GuidEmpty(),
            studentUploads: [],
            screenings: [],
            districts: [],
            exams: [],
            studentInfo: [],
            sites: [],
            examAcuities: [],
            examAdd: [],
            examBase: [],
            examCD: [],
            examCTDistance: [],
            examCTNear: [],
            examCyl: [],
            examDropsUsed: [],
            examIOP: [],
            examNRA: [],
            examPRA: [],
            examPrism: [],
            examProvider: [],
            examSphere: [],
            examWearSchedule: [],
            impressions: [],
            doctors: [],
            doctorSig: './img/blank.png',
            impressionName: '',
            searchWord: '',
            impressionsSearch: [],
            showImpressionSearchGrid: false,
            showImpressionModel: false,
            showExam: false,
            enableManualAddImpression: false,
            siteId: userFunctions.GuidEmpty(),
            site: '',
            examDate: '',
            mvcVisitLocation: '',
            preExamData: [],
            HSRxODSphere: '',
            HSRxODCyl: '',
            HSRxODAxis: '',
            HSRxODAdd: '',
            HSRxOSSphere: '',
            HSRxOSCyl: '',
            HSRxOSAxis: '',
            HSRxOSAdd: '',
            AutorefractorODSphere: '',
            AutorefractorODCyl: '',
            AutorefractorODAxis: '',
            AutorefractorOSSphere: '',
            AutorefractorOSCyl: '',
            AutorefractorOSAxis: '',
            Stereo3Shapes: '',
            Stereo8Randot: '',
            Color12: '',
            DistancePDOD: '',
            DistancePDOS: '',
            DistanceTotal: '',
            OcularHistory: '',
            Allergies: '',
            MedicalHistory: '',
            PretestComments: '',
            IEP504Plan: false,
            loading: false,
            showToast: false,
            toastMessage: '',
            a1: false,
            a2: false,
            a3: false,
            a4: false,
            a5: false,
            a6: false,
            a7: false,
            a8: false,
            a9: false,
            a10: false,
            a11: false,
            a12: false,
            a13: false,
            a14: false,
            a15: false,
            a16: false,
            a17: false,
            a18: false,
            Dilation: false,
            preferredName: '',
            showToastError: false,
            toastErrorMessage: '',
            isDoctor: false,
            labFrames: [],
            hover: false,
            StudentUploads: true,
            StudentScreenings: true,
            StudentExams: true,
            examSaved: true,
            showSaveModal: false,
            diabetesYear: '',
            a1c: ''
        };
    }

    async componentDidMount() {
        const isInRole = await userFunctions.IsInRole("Doctor");
        const isUserExamReadOnly = await userFunctions.IsInRole("ExamReadOnly");
        this.setState({ isDoctor: isInRole, disabled: isUserExamReadOnly });


        this.getSites();
        this.getDropdownValues();

        this.query = new URLSearchParams(window.location.search);
        this.studentId = this.query.get('studentId');
        this.examId = this.query.get('examId');
        if (this.studentId !== userFunctions.GuidEmpty() && this.studentId !== undefined && this.studentId != null) {
            this.setState({ studentId: this.studentId });
            //Get the student and load the page fields
            this.GetStudentById(this.studentId);
            this.getStudentUploads(this.studentId);
            this.GetExamGrid(this.studentId);
            //this.getLabFrames(this.studentId);
            this.getScreeningGridForExam(this.studentId);
        }
        if (this.examId !== userFunctions.GuidEmpty() && this.examId !== undefined && this.examId != null) {
            this.getImpressionsByExamId(this.examId);
            this.getExam(this.examId);
            this.setState({ showExam: true });
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.getImpressionsByKeyword();
        }
    }

    render() {
        return (
            <div style={{ position: 'relative' }} onMouseLeave={(e) => {
                if (!this.state.examSaved) {
                    this.setState({ showSaveModal: true });
                }

            }}>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                {this.state.showToastError ?
                    <Toast className="toast-error">
                        <ToastBody className="toast-body">
                            {this.state.toastErrorMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                <Container style={{ marginTop: '25px' }}>
                    <Row className="print-show" style={{ marginBottom: '20px' }}>
                        <Col>
                            <div style={{ width: '100%' }}>
                                <div style={{ float: 'left', width: '50%', verticalAlign: 'middle' }}>
                                    <img src="./img/print_logo.jpg" width="275px" height="175px" alt="Eye Thrive" />
                                </div>
                                <div style={{ float: 'right', width: '50%', height: '175px', paddingTop: '75px', textAlign: 'center' }}>
                                    5501 Delmar Blvd., Suite B580, St. Louis, MO 63112<br />
                                    Phone: 314-736-1400 | Fax: 314-736-1425 | Email: info@eyethrive.org
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <h3>Exam Pre-Test</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>

                                            <table className="preExamTable">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Name
                                                        </td>
                                                        <td>
                                                            {this.state.studentInfo?.firstName} {this.state.studentInfo?.lastName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Preferred Name
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => {
                                                                    this.setState({ preferredName: e.target.value, examSaved: false });
                                                                }}
                                                                placeholder='Preferred Name'
                                                                className={''}
                                                                value={this.state.preferredName}
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            DOB
                                                        </td>
                                                        <td>
                                                            {this.state.studentInfo?.dateOfBirthText}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Site
                                                        </td>
                                                        <td>
                                                            {this.state.studentInfo?.site}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Grade
                                                        </td>
                                                        <td>
                                                            {this.state.studentInfo?.grade}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Exam Date
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type='date'
                                                                onChange={(e) => {
                                                                    this.setState({ examDate: e.target.value, examSaved: false });
                                                                }}
                                                                value={this.state.examDate}
                                                                style={this.state.a13 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            MVC Visit Location
                                                        </td>
                                                        <td>
                                                            <Autocomplete
                                                                suggestions={this.state.sites}
                                                                placeholder="MVC Visit Location"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.setState({ siteId: e.Id, examSaved: false })
                                                                }}
                                                                value={this.state.site}
                                                                Focus={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table cellPadding={3}>
                                <tbody>
                                    <tr>
                                        <td colSpan={5} align="left" style={{ fontWeight: '500' }}>
                                            HSRx (current glasses)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            OD
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                defaultValue={this.state.SphereDefaultValue}
                                                onChange={(e) => this.setState({ HSRxODSphere: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.HSRxODSphere}
                                            >
                                                <option key={0} value={0}>---sphere---</option>
                                                {this.state.examSphere.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ HSRxODCyl: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.HSRxODCyl}
                                            >
                                                <option key={0} value={0}>---cyl---</option>
                                                {this.state.examCyl.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ HSRxODAxis: e.target.value, examSaved: false });
                                                }}
                                                maxLength={3}
                                                onBlur={() => this.setState({
                                                    HSRxODAxis: userFunctions.PadWithZeros(this.state.HSRxODAxis, 3)
                                                })}
                                                placeholder='axis'
                                                className={'miniInput'}
                                                value={this.state.HSRxODAxis}
                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                            />
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ HSRxODAdd: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.HSRxODAdd}
                                            >
                                                <option key={0} value={0}>---add---</option>
                                                {this.state.examAdd.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            OS
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ HSRxOSSphere: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.HSRxOSSphere}
                                            >
                                                <option key={0} value={0}>---sphere---</option>
                                                {this.state.examSphere.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ HSRxOSCyl: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.HSRxOSCyl}
                                            >
                                                <option key={0} value={0}>---cyl---</option>
                                                {this.state.examCyl.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ HSRxOSAxis: e.target.value });
                                                }}
                                                placeholder='axis'
                                                maxLength={3}
                                                onBlur={() => this.setState({
                                                    HSRxOSAxis: userFunctions.PadWithZeros(this.state.HSRxOSAxis, 3)
                                                })}
                                                className={'miniInput'}
                                                value={this.state.HSRxOSAxis}
                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                            />
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ HSRxOSAdd: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.HSRxOSAdd}
                                            >
                                                <option key={0} value={0}>---add---</option>
                                                {this.state.examAdd.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} align="left" style={{ fontWeight: '500', paddingTop: '10px' }}>
                                            Autorefractor
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            OD
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ AutorefractorODSphere: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.AutorefractorODSphere}
                                            >
                                                <option key={0} value={0}>---sphere---</option>
                                                {this.state.examSphere.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ AutorefractorODCyl: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.AutorefractorODCyl}
                                            >
                                                <option key={0} value={0}>---cyl---</option>
                                                {this.state.examCyl.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ AutorefractorODAxis: e.target.value, examSaved: false });
                                                }}
                                                placeholder='axis'
                                                maxLength={3}
                                                onBlur={() => this.setState({
                                                    AutorefractorODAxis: userFunctions.PadWithZeros(this.state.AutorefractorODAxis, 3)
                                                })}
                                                className={'miniInput'}
                                                value={this.state.AutorefractorODAxis}
                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                            />
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            OS
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ AutorefractorOSSphere: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.AutorefractorOSSphere}
                                            >
                                                <option key={0} value={0}>---sphere---</option>
                                                {this.state.examSphere.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control"
                                                onChange={(e) => this.setState({ AutorefractorOSCyl: e.target.value, a6: false, examSaved: false })}
                                                value={this.state.AutorefractorOSCyl}
                                            >
                                                <option key={0} value={0}>---cyl---</option>
                                                {this.state.examCyl.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                onChange={(e) => {
                                                    this.setState({ AutorefractorOSAxis: e.target.value, examSaved: false });
                                                }}
                                                placeholder='axis'
                                                maxLength={3}
                                                onBlur={() => this.setState({
                                                    AutorefractorOSAxis: userFunctions.PadWithZeros(this.state.AutorefractorOSAxis, 3)
                                                })}
                                                className={'miniInput'}
                                                value={this.state.AutorefractorOSAxis}
                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                            />
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="examMeasurementsTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table cellPadding={3}>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} align="left">
                                                            Stereo
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => { this.setState({ Stereo3Shapes: e.target.value, examSaved: false }); }}
                                                                className={'tinyInput'}
                                                                value={this.state.Stereo3Shapes}
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                        <td align="left">
                                                            /3 shapes
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => { this.setState({ Stereo8Randot: e.target.value, examSaved: false }); }}
                                                                className={'tinyInput'}
                                                                value={this.state.Stereo8Randot}
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                        <td align="left">
                                                            /8 randot
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            <table cellPadding={3} style={{ marginTop: '10px' }}>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} align="left">
                                                            Color (Ishihara)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right">
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => { this.setState({ Color12: e.target.value, examSaved: false }); }}
                                                                className={'tinyInput'}
                                                                value={this.state.Color12}
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                        <td align="left">
                                                            /12
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-control" style={{ opacity: '0', marginBottom: '10px' }}></div>
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            <table cellPadding={3}>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4} align="left">
                                                            Distance PD
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            OD
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => { this.setState({ DistancePDOD: e.target.value, examSaved: false }); }}
                                                                className={'smallInput'}
                                                                value={this.state.DistancePDOD}
                                                                placeholder=''
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                        <td>
                                                            OS
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => { this.setState({ DistancePDOS: e.target.value, examSaved: false }); }}
                                                                className={'smallInput'}
                                                                value={this.state.DistancePDOS}
                                                                onBlur={() => {
                                                                    var total = userFunctions.CalculateTotal(this.state.DistancePDOD, this.state.DistancePDOS);
                                                                    if (total != 0) {
                                                                        this.setState({
                                                                            DistanceTotal: total.toString()
                                                                        });
                                                                    }
                                                                }}
                                                                placeholder=''
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Total
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => { this.setState({ DistanceTotal: e.target.value, examSaved: false }); }}
                                                                className={'smallInput'}
                                                                value={this.state.DistanceTotal}
                                                                placeholder=''
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        {/*------------------------------------------------------------- */}
                        {/*------------------------------------------------------------- Column #2 */}
                        {/*------------------------------------------------------------- */}
                        <Col xl='6' lg='2' md='2' sm='2' xs='2'>
                            <div className={"showHideContainer"}>
                                <Row>
                                    <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                        <table className="showHideTable">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Show/Hide:
                                                    </td>
                                                    <td>
                                                        Student Uploads
                                                        <input type="checkbox" checked={this.state.StudentUploads} onChange={(e) => { this.setState({ StudentUploads: e.target.checked }); }}></input>
                                                    </td>
                                                    <td>
                                                        Screenings
                                                        <input type="checkbox" checked={this.state.StudentScreenings} onChange={(e) => { this.setState({ StudentScreenings: e.target.checked }); }}></input>
                                                    </td>
                                                    <td>
                                                        Exams
                                                        <input type="checkbox" checked={this.state.StudentExams} onChange={(e) => { this.setState({ StudentExams: e.target.checked }); }}></input>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                {this.state.StudentUploads ?
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                            <div style={{ maxHeight: '200px', overFlow: 'auto' }}>
                                                <table className="gridTable2" style={{ marginBottom: '10px', width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                File Name
                                                            </th>
                                                            <th>
                                                                File Type
                                                            </th>
                                                            <th>
                                                                Upload Date
                                                            </th>
                                                            <th>
                                                                Note
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.studentUploads && this.state.studentUploads.length > 0 ?
                                                            this.state.studentUploads.map((item, i) =>
                                                                <tr key={i} style={{ cursor: 'pointer' }}>
                                                                    <td><div style={{ color: 'cornflowerblue', cursor: 'pointer' }} onClick={() => { this.openFile(item.id, item.url) }}>{item.displayName}</div></td>
                                                                    <td>{item.fileType}</td>
                                                                    <td>{item.dateUploaded}</td>
                                                                    <td>{item.note}</td>
                                                                </tr>
                                                            )
                                                            :
                                                            <tr>
                                                                <td colSpan={4} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                                    No Results Found
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row> : null}
                                {this.state.StudentScreenings ?
                                    <Row>
                                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                            <div style={{ maxHeight: '200px', overFlow: 'auto' }}>
                                                <table className="gridTable2" style={{ marginBottom: '10px', width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Screening Date
                                                            </th>
                                                            <th>
                                                                OD Far
                                                            </th>
                                                            <th>
                                                                OS Far
                                                            </th>
                                                            <th>
                                                                OD Near
                                                            </th>
                                                            <th>
                                                                OS Near
                                                            </th>
                                                            <th>
                                                                Notes
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.screenings && this.state.screenings.length > 0 ?
                                                            this.state.screenings.map((item, i) =>
                                                                <tr key={i} style={{ cursor: 'pointer' }}>
                                                                    <td>{item.screeningDate}</td>
                                                                    <td>{item.dvaRight}</td>
                                                                    <td>{item.dvaLeft}</td>
                                                                    <td>{item.nvaRight}</td>
                                                                    <td>{item.nvaLeft}</td>
                                                                    <td>{item.note}</td>
                                                                </tr>
                                                            )
                                                            :
                                                            <tr>
                                                                <td colSpan={6} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                                    No Results Found
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row> : null}
                                {this.state.StudentExams ?
                                    <Row>
                                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                            <div style={{ maxHeight: '200px', overFlow: 'auto' }}>
                                                <table className="gridTable2" style={{ marginBottom: '10px', width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Exam Date
                                                            </th>
                                                            <th>
                                                                Number of glasses
                                                            </th>
                                                            <th>
                                                                Referral
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.exams && this.state.exams.length > 0 ?
                                                            this.state.exams.map((item, i) =>
                                                                <tr key={i}>
                                                                    {this.state.isDoctor ? <td style={{ cursor: 'pointer' }} onClick={() => window.open("/ExamPreTest?studentId=" + this.state.studentId + "&examId=" + item.id, '_blank')}>{item.examDate}</td> : <td style={{ cursor: 'default' }}>{item.examDate}</td>}
                                                                    <td>{item.numberOfGlasses}</td>
                                                                    {item.referral && item.referralUrl != null ? <td><div style={{ color: '#445AE4', fontWeight: '500', cursor: 'pointer', display: 'flex', width: '100%', height: '100%' }} onClick={async () => await this.openReferral(item.id)}>View</div></td> : <td></td>}
                                                                </tr>
                                                            )
                                                            :
                                                            <tr>
                                                                <td colSpan={3} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                                    No Results Found
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row> : null}
                            </div>
                            <Row>
                                <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                    <table id="historyTable" className="healthHistoryTable">
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} align="left">
                                                    <label style={{ width: 'fit-content', fontSize: '22px', fontWeight: '600', paddingTop: '10px' }}>Health History Per Current Consent Form</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right">
                                                    Has your child ever received an eye exam?
                                                </td>
                                                <td>
                                                    <div style={{ display: 'flex', width: 'fit-content', alignItems: 'center' }}>
                                                        <Input
                                                            type='radio'
                                                            name='receivedExam'
                                                            onChange={(e) => { this.setState({ ReceivedEyeExam: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                                            checked={this.state.ReceivedEyeExam == 'Yes' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;Yes&nbsp;&nbsp;
                                                        <Input
                                                            type='radio'
                                                            name='receivedExam'
                                                            onChange={(e) => { this.setState({ ReceivedEyeExam: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                                            checked={this.state.ReceivedEyeExam == 'No' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;

                                                        If yes, was it from Eye Thrive?

                                                        &nbsp;<input
                                                            type='radio'
                                                            name='receivedexamfromeyethrive'
                                                            onChange={(e) => { this.setState({ ReceivedEyeExamFromEyeThrive: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                                            checked={this.state.ReceivedEyeExamFromEyeThrive == 'Yes' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;Yes&nbsp;&nbsp;
                                                        <input
                                                            type='radio'
                                                            name='receivedexamfromeyethrive'
                                                            onChange={(e) => { this.setState({ ReceivedEyeExamFromEyeThrive: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                                            checked={this.state.ReceivedEyeExamFromEyeThrive == 'No' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;No
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right">
                                                    Has your child ever been prescribed glasses?
                                                </td>
                                                <td>
                                                    <div style={{ display: 'flex', width: 'fit-content', alignItems: 'center' }}>
                                                        <input
                                                            type='radio'
                                                            name='prescribedglasses'
                                                            onChange={(e) => { this.setState({ PrescribedGlasses: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                                            checked={this.state.PrescribedGlasses == 'Yes' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;Yes&nbsp;&nbsp;
                                                        <input
                                                            type='radio'
                                                            name='prescribedglasses'
                                                            onChange={(e) => { this.setState({ PrescribedGlasses: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                                            checked={this.state.PrescribedGlasses == 'No' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;

                                                        If yes, how long ago?&nbsp;

                                                        <input
                                                            name="medHistory"
                                                            type='text'
                                                            onChange={(e) => { this.setState({ PrescribedGlassesHowLongAgo: e.target.value, examSaved: false }) }}
                                                            value={this.state.PrescribedGlassesHowLongAgo ?? ''}
                                                            className="form-control"
                                                            style={{ pointerEvents: 'none' }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right">
                                                    Does your child wear glasses now?
                                                </td>
                                                <td colSpan={3}>
                                                    <input
                                                        type='radio'
                                                        name='wearsglassesnow'
                                                        onChange={(e) => { this.setState({ ChildWearsGlassesNow: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                                        checked={this.state.ChildWearsGlassesNow == 'Yes' ? true : false}
                                                        className="form-control-checkbox"
                                                        style={{ pointerEvents: 'none' }}
                                                    />&nbsp;Yes&nbsp;&nbsp;
                                                    <input
                                                        type='radio'
                                                        name='wearsglassesnow'
                                                        onChange={(e) => { this.setState({ ChildWearsGlassesNow: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                                        checked={this.state.ChildWearsGlassesNow == 'No' ? true : false}
                                                        className="form-control-checkbox"
                                                        style={{ pointerEvents: 'none' }}
                                                    />&nbsp;No
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right">
                                                    Does your child complain of blurry vision?
                                                </td>
                                                <td colSpan={3}>
                                                    <input
                                                        type='radio'
                                                        name='blurryvision'
                                                        onChange={(e) => { this.setState({ BlurryVision: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                                        checked={this.state.BlurryVision == 'Yes' ? true : false}
                                                        className="form-control-checkbox"
                                                        style={{ pointerEvents: 'none' }}
                                                    />&nbsp;Yes&nbsp;&nbsp;
                                                    <input
                                                        type='radio'
                                                        name='blurryvision'
                                                        onChange={(e) => { this.setState({ BlurryVision: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                                        checked={this.state.BlurryVision == 'No' ? true : false}
                                                        className="form-control-checkbox"
                                                        style={{ pointerEvents: 'none' }}
                                                    />&nbsp;No
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right">
                                                    Has your child ever injured or had surgeries on his/her eyes?
                                                </td>
                                                <td colSpan={3}>
                                                    <input
                                                        type='radio'
                                                        name='surgeries'
                                                        onChange={(e) => { this.setState({ SurgeriesOnEyes: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                                        checked={this.state.SurgeriesOnEyes == 'Yes' ? true : false}
                                                        className="form-control-checkbox"
                                                        style={{ pointerEvents: 'none' }}
                                                    />&nbsp;Yes&nbsp;&nbsp;
                                                    <input
                                                        type='radio'
                                                        name='surgeries'
                                                        onChange={(e) => { this.setState({ SurgeriesOnEyes: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                                        checked={this.state.SurgeriesOnEyes == 'No' ? true : false}
                                                        className="form-control-checkbox"
                                                        style={{ pointerEvents: 'none' }}
                                                    />&nbsp;No
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right" valign="middle">
                                                    Does your child have diabetes?
                                                </td>
                                                <td align="left" valign="top" colSpan={3}>
                                                    <div style={{ width: 'fit-content', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <input
                                                            type='radio'
                                                            name='ChildHaveDiabetes'
                                                            onChange={(e) => { this.setState({ ChildHaveDiabetes: e.target.checked ? 'Yes' : 'No', examSaved: false }) }}
                                                            checked={this.state.ChildHaveDiabetes == 'Yes' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;Yes&nbsp;&nbsp;
                                                        <input
                                                            type='radio'
                                                            name='ChildHaveDiabetes'
                                                            onChange={(e) => { this.setState({ ChildHaveDiabetes: e.target.checked ? 'No' : 'Yes', examSaved: false }) }}
                                                            checked={this.state.ChildHaveDiabetes == 'No' ? true : false}
                                                            className="form-control-checkbox"
                                                            style={{ pointerEvents: 'none' }}
                                                        />&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                            <span>If yes, A1C Number?</span>&nbsp;
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => {
                                                                    this.setState({ a1c: e.target.value });
                                                                }}
                                                                name='A1CNumber'
                                                                placeholder={''}
                                                                value={this.state.a1c ?? ''}
                                                                style={{ pointerEvents: 'none', padding: '3px', width: '45px' }}
                                                            />
                                                        </div>&nbsp;&nbsp;
                                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                            <span>If yes, year of diabetes diagnosis?</span>&nbsp;
                                                            <Input
                                                                type='numeric'
                                                                onChange={(e) => {
                                                                    this.setState({ diabetesYear: e.target.value });
                                                                }}
                                                                name='DiabetesYear'
                                                                maxLength={4}
                                                                placeholder={''}
                                                                value={this.state.diabetesYear ?? ''}
                                                                style={{ pointerEvents: 'none', padding: '3px', width: '50px' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" align="right">
                                                    Please list any medications your child is currently taking
                                                </td>
                                                <td colSpan={2}>
                                                    <textarea
                                                        onChange={(e) => { this.setState({ ListOfMedications: e.target.value, examSaved: false }) }}
                                                        value={this.state.ListOfMedications ?? ''}
                                                        className="form-control"
                                                        style={{ height: '60px', pointerEvents: 'none' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" align="right">
                                                    Please list any food or medication allergies your child has
                                                </td>
                                                <td colSpan={3}>
                                                    <textarea
                                                        onChange={(e) => { this.setState({ ListOfAllergies: e.target.value, examSaved: false }) }}
                                                        value={this.state.ListOfAllergies ?? ''}
                                                        className="form-control"
                                                        style={{ height: '60px', pointerEvents: 'none' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" align="right">
                                                    Please list any medical conditions your child as been diagnosed with
                                                </td>
                                                <td colSpan={3}>
                                                    <textarea
                                                        onChange={(e) => { this.setState({ MedicalConditions: e.target.value, examSaved: false }) }}
                                                        value={this.state.MedicalConditions ?? ''}
                                                        className="form-control"
                                                        style={{ height: '60px', pointerEvents: 'none' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" align="right">
                                                    Please list any family history of eye disease
                                                </td>
                                                <td colSpan={3}>
                                                    <textarea
                                                        onChange={(e) => { this.setState({ EyeDiseaseHistory: e.target.value, examSaved: false }) }}
                                                        value={this.state.EyeDiseaseHistory ?? ''}
                                                        className="form-control"
                                                        style={{ height: '60px', pointerEvents: 'none' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" align="right">
                                                    Health History Comments
                                                </td>
                                                <td colSpan={3}>
                                                    <textarea
                                                        onChange={(e) => {
                                                            this.setState({ PretestComments: e.target.value, examSaved: false });
                                                        }}
                                                        value={this.state.PretestComments}
                                                        style={{ width: '100%', height: '75px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                </td>
                                                <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <Input
                                                        type='checkbox'
                                                        onChange={(e) => { this.setState({ IEP504Plan: !this.state.IEP504Plan, examSaved: false }) }}
                                                        id='chkIEP04Plan'
                                                        checked={this.state.IEP504Plan}
                                                        className="form-control-checkbox"
                                                    />
                                                    <label style={{ marginLeft: '5px', marginBottom: '0' }}>IEP / 504 Plan</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                </td>
                                                <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                                    <Button
                                                        color='primary'
                                                        onClick={async () => window.location.href = "/StudentSearch"}
                                                        style={{ marginTop: '40px', whiteSpace: 'nowrap', marginRight: '20px' }}
                                                        disabled={this.state.loading ? true : false}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        color='primary'
                                                        onClick={async () => await this.savePreTest(true)}
                                                        style={{ marginTop: '40px', whiteSpace: 'nowrap', }}
                                                        disabled={this.state.loading ? true : false}
                                                    >
                                                        Pre-Test Complete
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                        </Col>
                        {/*<Col xl='5' lg='6' md='6' sm='12' xs='12'>*/}
                        {/*    C*/}
                        {/*</Col>*/}
                        {/*<Col xl='6' lg='2' md='2' sm='2' xs='2' style={{ paddingRight: 0, paddingLeft: 0 }}>*/}
                        {/*   D*/}
                        {/*</Col>*/}
                    </Row>
                </Container >


                {/*Doctor Exam Page*/}
                < Container style={{ marginTop: '25px', borderTop: this.state.showExam ? '2px dashed #eee' : '0' }
                }>
                    {
                        this.state.showExam ?
                            <Row>
                                <Col>
                                    <Row style={{ marginTop: '25px' }}>
                                        <Col>
                                            <h3>Exam</h3>
                                        </Col>
                                    </Row>
                                    <table className="examTable1" style={{ margin: '0 0 1.4vh 0' }}>
                                        <tbody>
                                            <tr>
                                                <td valign="top">
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <table className="examTable1" style={{ marginTop: '1vh', marginBottom: '0' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px' }} align="left" valign="top">
                                                                        Chief Complaint
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} align="left" style={{ fontWeight: '500' }}>
                                                                        Distance Blur
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ DistanceBlurOD: !this.state.DistanceBlurOD, examSaved: false }) }}
                                                                            checked={this.state.DistanceBlurOD}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left" style={{ paddingRight: '1vw' }}>
                                                                        OD
                                                                    </td>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ DistanceBlurConstant: !this.state.DistanceBlurConstant, examSaved: false }) }}
                                                                            checked={this.state.DistanceBlurConstant}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        Constant
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ DistanceBlurOS: !this.state.DistanceBlurOS, examSaved: false }) }}
                                                                            checked={this.state.DistanceBlurOS}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        OS
                                                                    </td>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ DistanceBlurIntermittent: !this.state.DistanceBlurIntermittent, examSaved: false }) }}
                                                                            checked={this.state.DistanceBlurIntermittent}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        Intermittent
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ DistanceBlurOU: !this.state.DistanceBlurOU, examSaved: false }) }}
                                                                            checked={this.state.DistanceBlurOU}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        OU
                                                                    </td>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ DistanceBlurNone: !this.state.DistanceBlurNone, examSaved: false }) }}
                                                                            checked={this.state.DistanceBlurNone}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        None
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} align="left" style={{ fontWeight: '500', marginTop: '10px' }}>
                                                                        Near Blur
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ NearBlurOD: !this.state.NearBlurOD, examSaved: false }) }}
                                                                            checked={this.state.NearBlurOD}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        OD
                                                                    </td>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ NearBlurConstant: !this.state.NearBlurConstant, examSaved: false }) }}
                                                                            checked={this.state.NearBlurConstant}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        Constant
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ NearBlurOS: !this.state.NearBlurOS, examSaved: false }) }}
                                                                            checked={this.state.NearBlurOS}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        OS
                                                                    </td>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ NearBlurIntermittent: !this.state.NearBlurIntermittent, examSaved: false }) }}
                                                                            checked={this.state.NearBlurIntermittent}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        Intermittent
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ NearBlurOU: !this.state.NearBlurOU, examSaved: false }) }}
                                                                            checked={this.state.NearBlurOU}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        OU
                                                                    </td>
                                                                    <td align="right">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ NearBlurNone: !this.state.NearBlurNone, examSaved: false }) }}
                                                                            checked={this.state.NearBlurNone}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        None
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table className="examTable1" style={{ marginLeft: '1.5vw', marginTop: '1vh' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px' }} align="left">
                                                                        Entering Acuities - Distance
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <b>SC:</b> OD 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesDistanceSCOD: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesDistanceSCOD}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td align="right" style={{ paddingLeft: '1.5vw' }}>
                                                                        <b>CC:</b> OD 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesDistanceCCOD: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesDistanceCCOD}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OS 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesDistanceSCOS: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesDistanceSCOS}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td align="right">
                                                                        OS 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesDistanceCCOS: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesDistanceCCOS}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OU 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesDistanceSCOU: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesDistanceSCOU}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td align="right">
                                                                        OU 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesDistanceCCOU: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesDistanceCCOU}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table className="examTable1" style={{ marginLeft: '1.5vw', marginTop: '1vh', marginBottom: '0' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px' }} align="left">
                                                                        Entering Acuities - Near
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        <b>SC:</b> OD 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesNearSCOD: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesNearSCOD}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td align="right" style={{ paddingLeft: '1.5vw' }}>
                                                                        <b>CC:</b> OD 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesNearCCOD: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesNearCCOD}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OS 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesNearSCOS: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesNearSCOS}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td align="right">
                                                                        OS 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesNearCCOS: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesNearCCOS}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OU 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesNearSCOU: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesNearSCOU}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td align="right">
                                                                        OU 20/
                                                                    </td>
                                                                    <td alig="left">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ EnteringAcuitiesNearCCOU: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.EnteringAcuitiesNearCCOU}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={10} valign="top" align="left">
                                                    <textarea
                                                        onChange={(e) => {
                                                            this.setState({ ChiefComplaintNotes: e.target.value, examSaved: false });
                                                        }}
                                                        placeholder="Notes"
                                                        value={this.state.ChiefComplaintNotes}
                                                        style={{ width: '30vw', height: '20vh', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table cellSpacing={10}>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                                    Pupils
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">
                                                                    <Input
                                                                        type='checkbox'
                                                                        onChange={(e) => { this.setState({ PupilsPERRL: !this.state.PupilsPERRL, examSaved: false }) }}
                                                                        checked={this.state.PupilsPERRL}
                                                                        className="form-control-checkbox"
                                                                    />
                                                                </td>
                                                                <td align="left">
                                                                    PERRL
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">
                                                                    <Input
                                                                        type='checkbox'
                                                                        onChange={(e) => { this.setState({ PupilsAPD: !this.state.PupilsAPD, examSaved: false }) }}
                                                                        checked={this.state.PupilsAPD}
                                                                        className="form-control-checkbox"
                                                                    />
                                                                </td>
                                                                <td align="left">
                                                                    (-) APD
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                    CT
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} align="left" valign="top">
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItem: 'flex-start' }}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td align="right" valign="middle" style={{ paddingTop: '15px' }}>
                                                                        Distance
                                                                    </td>
                                                                    <td alig="left" valign="bottom">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ CTDistance: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.CTDistance}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examCTDistance.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right" valign="middle" style={{ paddingBottom: '15px' }}>
                                                                        Near
                                                                    </td>
                                                                    <td align="left" valign="top">
                                                                        <select className="form-control width-fit-content"
                                                                            onChange={(e) => this.setState({ CTNear: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.CTNear}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examCTNear.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <textarea
                                                            onChange={(e) => {
                                                                this.setState({ CTNotes: e.target.value, examSaved: false });
                                                            }}
                                                            placeholder="Notes"
                                                            value={this.state.CTNotes}
                                                            style={{ width: '50%', height: '100px', marginLeft: '.5vw', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                    NPC
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} align="right">
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Input
                                                            type='checkbox'
                                                            onChange={(e) => { this.setState({ PNCTTN: !this.state.PNCTTN, examSaved: false }) }}
                                                            checked={this.state.PNCTTN}
                                                            className="form-control-checkbox"
                                                            style={{ marginRight: '5px', marginBottom: '0', marginTop: '0' }}
                                                        />

                                                        <label style={{ marginRight: '15px', marginBottom: '0' }}>TTN</label>

                                                        <label style={{ marginRight: '5px', marginBottom: '0' }}>CM</label>

                                                        <Input
                                                            type='text'
                                                            onChange={(e) => {
                                                                this.setState({ NPCCM: e.target.value, examSaved: false });
                                                            }}
                                                            placeholder=''
                                                            value={this.state.NPCCM}
                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0', width: '660px' }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                    EOMs
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" style={{ display: 'flex', flextDirection: 'row', alignItems: 'center' }}>
                                                    <Input
                                                        type='checkbox'
                                                        onChange={(e) => { this.setState({ EOMs: !this.state.EOMs, examSaved: false }) }}
                                                        checked={this.state.EOMs}
                                                        className="form-control-checkbox"
                                                        style={{ marginTop: '0' }}
                                                    />
                                                    <label style={{ margin: '0 0 3px 5px' }}>nl</label>
                                                    <Input
                                                        type='text'
                                                        onChange={(e) => {
                                                            this.setState({ EOMsNL: e.target.value, examSaved: false });
                                                        }}
                                                        placeholder=''
                                                        value={this.state.EOMsNL}
                                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0', width: '710px', marginLeft: '5px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                    <table style={{ width: 'fit-content', marginRight: '4vw' }}>
                                                        <tr>
                                                            <td style={{ width: '100px', fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                                CVF OD
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3} align="left" style={{ display: 'flex', flextDirection: 'row', alignItems: 'center', marginBottom: '0' }}>
                                                                <Input
                                                                    type='checkbox'
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            CVF: !this.state.CVF,
                                                                            GridDropdown1: !this.state.CVF ? '+' : '0',
                                                                            GridDropdown2: !this.state.CVF ? '+' : '0',
                                                                            GridDropdown3: !this.state.CVF ? '+' : '0',
                                                                            GridDropdown4: !this.state.CVF ? '+' : '0', examSaved: false
                                                                        })
                                                                    }}
                                                                    checked={this.state.CVF}
                                                                    className="form-control-checkbox"
                                                                    style={{ marginTop: '0' }}
                                                                />
                                                                <label style={{ margin: '0 0 3px 5px' }}>nl</label>
                                                                <Input
                                                                    type='text'
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            CVFNL: e.target.value, examSaved: false
                                                                        });
                                                                    }}
                                                                    placeholder=''
                                                                    value={this.state.CVFNL}
                                                                    style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0', width: '15vw', marginLeft: '5px' }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table style={{ marginTop: '1vh', marginBottom: '1vh' }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ GridDropdown1: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.GridDropdown1}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                            <td style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ GridDropdown2: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.GridDropdown2}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ borderRight: '1px solid #ccc', padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ GridDropdown3: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.GridDropdown3}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                            <td style={{ padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ GridDropdown4: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.GridDropdown4}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table>
                                                        <tr>
                                                            <td style={{ width: '100px', fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                                CVF OS
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3} align="left" style={{ display: 'flex', flextDirection: 'row', alignItems: 'center', marginBottom: '0' }}>
                                                                <Input
                                                                    type='checkbox'
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            CVFOS: !this.state.CVFOS,
                                                                            OSGridDropdown1: !this.state.CVFOS ? '+' : '0',
                                                                            OSGridDropdown2: !this.state.CVFOS ? '+' : '0',
                                                                            OSGridDropdown3: !this.state.CVFOS ? '+' : '0',
                                                                            OSGridDropdown4: !this.state.CVFOS ? '+' : '0', examSaved: false
                                                                        })
                                                                    }}
                                                                    checked={this.state.CVFOS}
                                                                    className="form-control-checkbox"
                                                                    style={{ marginTop: '0' }}
                                                                />
                                                                <label style={{ margin: '0 0 3px 5px' }}>nl</label>
                                                                <Input
                                                                    type='text'
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            CVFNLOS: e.target.value, examSaved: false
                                                                        });
                                                                    }}
                                                                    placeholder=''
                                                                    value={this.state.CVFNLOS}
                                                                    style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0', width: '15vw', marginLeft: '5px' }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table style={{ marginTop: '1vh', marginBottom: '1vh' }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ OSGridDropdown1: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.OSGridDropdown1}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                            <td style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ OSGridDropdown2: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.OSGridDropdown2}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ borderRight: '1px solid #ccc', padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ OSGridDropdown3: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.OSGridDropdown3}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                            <td style={{ padding: '10px' }}>
                                                                                <select className="form-control width-fit-content"
                                                                                    onChange={(e) => this.setState({ OSGridDropdown4: e.target.value, a6: false, examSaved: false })}
                                                                                    value={this.state.OSGridDropdown4}
                                                                                >
                                                                                    <option key={0} value={'0'}>---</option>
                                                                                    <option key={1} value={'+'}>+</option>
                                                                                    <option key={2} value={'-'}>-</option>
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px' }} align="left">
                                                                        Retinoscopy
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OD
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ RetinoscopyODSphere: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.RetinoscopyODSphere}
                                                                        >
                                                                            <option key={0} value={0}>---sphere---</option>
                                                                            {this.state.examSphere.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ RetinoscopyODCyl: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.RetinoscopyODCyl}
                                                                        >
                                                                            <option key={0} value={0}>---cyl---</option>
                                                                            {this.state.examCyl.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='text'
                                                                            onChange={(e) => {
                                                                                this.setState({ RetinoscopyODAxis: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder='axis'
                                                                            maxLength={3}
                                                                            onBlur={() => this.setState({
                                                                                RetinoscopyODAxis: userFunctions.PadWithZeros(this.state.RetinoscopyODAxis, 3)
                                                                            })}
                                                                            className={'miniInput'}
                                                                            value={this.state.RetinoscopyODAxis}
                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OS
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ RetinoscopyOSSphere: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.RetinoscopyOSSphere}
                                                                        >
                                                                            <option key={0} value={0}>---sphere---</option>
                                                                            {this.state.examSphere.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ RetinoscopyOSCyl: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.RetinoscopyOSCyl}
                                                                        >
                                                                            <option key={0} value={0}>---cyl---</option>
                                                                            {this.state.examCyl.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='text'
                                                                            onChange={(e) => {
                                                                                this.setState({ RetinoscopyOSAxis: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder='axis'
                                                                            maxLength={3}
                                                                            onBlur={() => this.setState({
                                                                                RetinoscopyOSAxis: userFunctions.PadWithZeros(this.state.RetinoscopyOSAxis, 3)
                                                                            })}
                                                                            className={'miniInput'}
                                                                            value={this.state.RetinoscopyOSAxis}
                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <textarea
                                                            onChange={(e) => {
                                                                this.setState({ RetinoscopyNotes: e.target.value, examSaved: false });
                                                            }}
                                                            placeholder="Notes"
                                                            value={this.state.RetinoscopyNotes}
                                                            style={{ width: '100%', height: '100px', marginTop: '10px', marginLeft: '.5vw', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" align="left">
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                                        Manifest
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right" valign="middle">
                                                                        OD
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestODSphere: e.target.value, FinalSRxODSphere: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestODSphere}
                                                                        >
                                                                            <option key={0} value={0}>---sphere---</option>
                                                                            {this.state.examSphere.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestODCyl: e.target.value, FinalSRxODCyl: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestODCyl}
                                                                        >
                                                                            <option key={0} value={0}>---cyl---</option>
                                                                            {this.state.examCyl.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='text'
                                                                            onChange={(e) => {
                                                                                this.setState({ ManifestODAxis: e.target.value, FinalSRxODAxis: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder='axis'
                                                                            maxLength={3}
                                                                            onBlur={() => this.setState({
                                                                                ManifestODAxis: userFunctions.PadWithZeros(this.state.ManifestODAxis, 3),
                                                                                FinalSRxODAxis: userFunctions.PadWithZeros(this.state.FinalSRxODAxis, 3)
                                                                            })}
                                                                            className={'miniInput'}
                                                                            value={this.state.ManifestODAxis}
                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right" valign="middle">
                                                                        OS
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestOSSphere: e.target.value, FinalSRxOSSphere: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestOSSphere}
                                                                        >
                                                                            <option key={0} value={0}>---sphere---</option>
                                                                            {this.state.examSphere.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestOSCyl: e.target.value, FinalSRxOSCyl: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestOSCyl}
                                                                        >
                                                                            <option key={0} value={0}>---cyl---</option>
                                                                            {this.state.examCyl.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='text'
                                                                            onChange={(e) => {
                                                                                this.setState({ ManifestOSAxis: e.target.value, FinalSRxOSAxis: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder='axis'
                                                                            maxLength={3}
                                                                            onBlur={() => this.setState({
                                                                                ManifestOSAxis: userFunctions.PadWithZeros(this.state.ManifestOSAxis, 3),
                                                                                FinalSRxOSAxis: userFunctions.PadWithZeros(this.state.FinalSRxOSAxis, 3)
                                                                            })}
                                                                            className={'miniInput'}
                                                                            value={this.state.ManifestOSAxis}
                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        Distance OU: 20/
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestDistanceOU: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestDistanceOU}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td align="right">
                                                                        Near OU: 20/
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            className="form-control"
                                                                            style={{ width: '5.2vw' }}
                                                                            onChange={(e) => this.setState({ ManifestNearOU: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestNearOU}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} align="left">
                                                                        <textarea
                                                                            onChange={(e) => {
                                                                                this.setState({ ManifestNotes: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder="Notes"
                                                                            value={this.state.ManifestNotes}
                                                                            style={{ width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px', color: 'rgba(255,255,255,0)' }} align="left">
                                                                        -
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ paddingLeft: '.5vw' }}>
                                                                        20/
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestOD20: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestOD20}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;ADD
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestADD: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestADD}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAdd.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;Near OU: 20/
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestNearOU20: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestNearOU20}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ paddingLeft: '.5vw' }}>
                                                                        20/
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestOS20: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestOS20}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;NRA
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestNRA: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestNRA}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examNRA.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;PRA
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ ManifestPRA: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.ManifestPRA}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examPRA.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" align="left">
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <table cellPadding={2} className="width-fit-content">
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6} style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                                        Slit Lamp
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight: '500' }}>
                                                                        OD
                                                                    </td>
                                                                    <td>
                                                                        nl
                                                                    </td>
                                                                    <td align="right" style={{ fontWeight: '500' }}>
                                                                        OS
                                                                    </td>
                                                                    <td>
                                                                        nl
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td align="left">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    SlitLampODSelectAll: !this.state.SlitLampODSelectAll,
                                                                                    SlitLampODII: !this.state.SlitLampODSelectAll,
                                                                                    SlitLampODConj: !this.state.SlitLampODSelectAll,
                                                                                    SlitLampODCornea: !this.state.SlitLampODSelectAll,
                                                                                    SlitLampODAC: !this.state.SlitLampODSelectAll,
                                                                                    SlitLampODIris: !this.state.SlitLampODSelectAll,
                                                                                    SlitLampODLens: !this.state.SlitLampODSelectAll, examSaved: false
                                                                                })
                                                                            }}
                                                                            checked={this.state.SlitLampODSelectAll}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left" style={{ width: '5vw', fontWeight: '500' }}>
                                                                        Select All
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    SlitLampOSSelectAll: !this.state.SlitLampOSSelectAll,
                                                                                    SlitLampOSII: !this.state.SlitLampOSSelectAll,
                                                                                    SlitLampOSConj: !this.state.SlitLampOSSelectAll,
                                                                                    SlitLampOSCornea: !this.state.SlitLampOSSelectAll,
                                                                                    SlitLampOSAC: !this.state.SlitLampOSSelectAll,
                                                                                    SlitLampOSIris: !this.state.SlitLampOSSelectAll,
                                                                                    SlitLampOSLens: !this.state.SlitLampOSSelectAll, examSaved: false
                                                                                })

                                                                            }}
                                                                            checked={this.state.SlitLampOSSelectAll}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left" style={{ fontWeight: '500' }}>
                                                                        Select All
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => {
                                                                                this.setState({ SlitLampODII: !this.state.SlitLampODII, examSaved: false })
                                                                            }}
                                                                            checked={this.state.SlitLampODII}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        I/I
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampOSII: !this.state.SlitLampOSII, examSaved: false }) }}
                                                                            checked={this.state.SlitLampOSII}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        I/I
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampODConj: !this.state.SlitLampODConj, examSaved: false }) }}
                                                                            checked={this.state.SlitLampODConj}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        conj
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampOSConj: !this.state.SlitLampOSConj, examSaved: false }) }}
                                                                            checked={this.state.SlitLampOSConj}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        conj
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampODCornea: !this.state.SlitLampODCornea }) }}
                                                                            checked={this.state.SlitLampODCornea}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        cornea
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampOSCornea: !this.state.SlitLampOSCornea, examSaved: false }) }}
                                                                            checked={this.state.SlitLampOSCornea}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        cornea
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampODAC: !this.state.SlitLampODAC, examSaved: false }) }}
                                                                            checked={this.state.SlitLampODAC}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        A/C
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampOSAC: !this.state.SlitLampOSAC, examSaved: false }) }}
                                                                            checked={this.state.SlitLampOSAC}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        A/C
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampODIris: !this.state.SlitLampODIris, examSaved: false }) }}
                                                                            checked={this.state.SlitLampODIris}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        iris
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampOSIris: !this.state.SlitLampOSIris, examSaved: false }) }}
                                                                            checked={this.state.SlitLampOSIris}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        iris
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampODLens: !this.state.SlitLampODLens, examSaved: false }) }}
                                                                            checked={this.state.SlitLampODLens}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        lens
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ SlitLampOSLens: !this.state.SlitLampOSLens, examSaved: false }) }}
                                                                            checked={this.state.SlitLampOSLens}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td align="left">
                                                                        lens
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div style={{ marginRight: '14vw' }}></div>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} style={{ fontWeight: '500', fontSize: '17px', paddingTop: '25px' }} align="left">
                                                                        IOP
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ width: '1.1vw' }}>
                                                                        OD
                                                                    </td>
                                                                    <td style={{ width: '1.2vw' }}>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ IOPOD: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.IOPOD}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examIOP.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td style={{ width: '1.2vw' }}>
                                                                        &nbsp;<Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ TaODiCare: !this.state.TaODiCare, examSaved: false }) }}
                                                                            checked={this.state.TaODiCare}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '1.1vw' }}>
                                                                        iCare
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        OS
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ IOPOS: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.IOPOS}
                                                                        >
                                                                            <option key={0} value={0}>---</option>
                                                                            {this.state.examIOP.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        &nbsp;<Input
                                                                            type='checkbox'
                                                                            onChange={(e) => { this.setState({ TaOSFT: !this.state.TaOSFT, examSaved: false }) }}
                                                                            checked={this.state.TaOSFT}
                                                                            className="form-control-checkbox"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        FT
                                                                    </td>
                                                                    <td align="left">
                                                                        <Input
                                                                            type='text'
                                                                            onChange={(e) => {
                                                                                this.setState({ TaOSFTText: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder=''
                                                                            value={this.state.TaOSFTText}
                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0', width: '15vw' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <textarea
                                                                            onChange={(e) => {
                                                                                this.setState({ TaNotes: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder="Notes"
                                                                            value={this.state.TaNotes}
                                                                            style={{ width: '25.7vw', height: '100px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={5}>
                                                    <textarea
                                                        onChange={(e) => {
                                                            this.setState({ SlitLampNotes: e.target.value, examSaved: false });
                                                        }}
                                                        placeholder="Notes"
                                                        value={this.state.SlitLampNotes}
                                                        style={{ width: '24vw', height: '100px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td align="left" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '15px' }} colSpan={5}>
                                                                    <Input
                                                                        type='checkbox'
                                                                        onChange={(e) => {
                                                                            this.setState({ Dilation: !this.state.Dilation, examSaved: false });
                                                                            setTimeout(function () {
                                                                                window.scrollTo(0, document.body.scrollHeight);
                                                                            }, 250);
                                                                        }}
                                                                        checked={this.state.Dilation}
                                                                        className="form-control-checkbox"
                                                                        style={{ marginBottom: '0', marginTop: '0' }}
                                                                    />&nbsp;<label style={{ fontWeight: '500', fontSize: '16px', margin: '0', padding: '0' }}> Dilation</label>
                                                                </td>
                                                            </tr>
                                                            {this.state.Dilation ?
                                                                <tr>
                                                                    <td>
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                        Drops Used&nbsp;
                                                                                        <select className="form-control width-fit-content"
                                                                                            style={{ width: 'fit-content' }}
                                                                                            onChange={(e) => this.setState({ DilationDropsUsed: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.DilationDropsUsed}
                                                                                        >
                                                                                            <option key={0} value={0}>---</option>
                                                                                            {this.state.examDropsUsed.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Time
                                                                                        &nbsp;<Input
                                                                                            type='time'
                                                                                            onChange={(e) => {
                                                                                                this.setState({ DilationTime: e.target.value, examSaved: false });
                                                                                            }}
                                                                                            placeholder=''
                                                                                            className={'timeInput'}
                                                                                            value={this.state.DilationTime}
                                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0', width: 'fit-content' }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={5}>
                                                                                        <textarea
                                                                                            onChange={(e) => {
                                                                                                this.setState({ DilationNotes: e.target.value, examSaved: false });
                                                                                            }}
                                                                                            placeholder="Notes"
                                                                                            value={this.state.DilationNotes}
                                                                                            style={{ width: '24vw', height: '100px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ width: '1.1vw', paddingTop: '15px' }}>
                                                                                        <Input
                                                                                            type='checkbox'
                                                                                            onChange={(e) => { this.setState({ DilationOD: !this.state.DilationOD, examSaved: false }) }}
                                                                                            checked={this.state.DilationOD}
                                                                                            className="form-control-checkbox"
                                                                                        />&nbsp;OD
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ width: '1.1vw' }}>
                                                                                        <Input
                                                                                            type='checkbox'
                                                                                            onChange={(e) => { this.setState({ DilationOS: !this.state.DilationOS, examSaved: false }) }}
                                                                                            checked={this.state.DilationOS}
                                                                                            className="form-control-checkbox"
                                                                                        />&nbsp;OS
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ width: '1.1vw' }}>
                                                                                        <Input
                                                                                            type='checkbox'
                                                                                            onChange={(e) => { this.setState({ DilationOU: !this.state.DilationOU, examSaved: false }) }}
                                                                                            checked={this.state.DilationOU}
                                                                                            className="form-control-checkbox"
                                                                                        />&nbsp;OU
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={5} style={{ fontWeight: '500', paddingTop: '15px' }}>
                                                                                        Dilated Refraction
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} align="left">
                                                                                        OD&nbsp;
                                                                                        <select className="form-control static-width-6vw"
                                                                                            onChange={(e) => this.setState({ DilatedRefractionODSphere: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.DilatedRefractionODSphere}
                                                                                        >
                                                                                            <option key={0} value={0}>---sphere---</option>
                                                                                            {this.state.examSphere.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                        &nbsp;<select className="form-control static-width-6vw"
                                                                                            onChange={(e) => this.setState({ DilatedRefractionODCyl: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.DilatedRefractionODCyl}>
                                                                                            <option key={0} value={0}>---cyl---</option>
                                                                                            {this.state.examCyl.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                        &nbsp;<Input
                                                                                            type='text'
                                                                                            onChange={(e) => {
                                                                                                this.setState({ DilatedRefractionODAxis: e.target.value, examSaved: false });
                                                                                            }}
                                                                                            placeholder='axis'
                                                                                            maxLength={3}
                                                                                            onBlur={() => this.setState({
                                                                                                DilatedRefractionODAxis: userFunctions.PadWithZeros(this.state.DilatedRefractionODAxis, 3)
                                                                                            })}
                                                                                            className={'miniInput'}
                                                                                            value={this.state.DilatedRefractionODAxis}
                                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                                        />
                                                                                        <label style={{ margin: '0 2px 0 7px' }}>20/</label>
                                                                                        <select className="form-control width-fit-content"
                                                                                            onChange={(e) => this.setState({ DilatedRefractionOD20: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.DilatedRefractionOD20}
                                                                                        >
                                                                                            <option key={0} value={0}>---</option>
                                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} align="left">
                                                                                        OS&nbsp;
                                                                                        <select className="form-control static-width-6vw"
                                                                                            onChange={(e) => this.setState({ DilatedRefractionOSSphere: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.DilatedRefractionOSSphere}
                                                                                            style={{ marginLeft: '3px' }}>
                                                                                            <option key={0} value={0}>---sphere---</option>
                                                                                            {this.state.examSphere.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}

                                                                                        </select>
                                                                                        &nbsp;<select className="form-control static-width-6vw"
                                                                                            onChange={(e) => this.setState({ DilatedRefractionOSCyl: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.DilatedRefractionOSCyl}>
                                                                                            <option key={0} value={0}>---cyl---</option>
                                                                                            {this.state.examCyl.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                        &nbsp;<Input
                                                                                            type='text'
                                                                                            onChange={(e) => {
                                                                                                this.setState({ DilatedRefractionOSAxis: e.target.value, examSaved: false });
                                                                                            }}
                                                                                            placeholder='axis'
                                                                                            maxLength={3}
                                                                                            onBlur={() => this.setState({
                                                                                                DilatedRefractionOSAxis: userFunctions.PadWithZeros(this.state.DilatedRefractionOSAxis, 3)
                                                                                            })}
                                                                                            className={'miniInput'}
                                                                                            value={this.state.DilatedRefractionOSAxis}
                                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                                        />
                                                                                        <label style={{ margin: '0 2px 0 7px' }}>20/</label>
                                                                                        <select className="form-control width-fit-content"
                                                                                            onChange={(e) => this.setState({ DilatedRefractionOS20: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.DilatedRefractionOS20}
                                                                                        >
                                                                                            <option key={0} value={0}>---</option>
                                                                                            {this.state.examAcuities.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                : null}
                                                            <tr>
                                                                <td>
                                                                    <table className="examCheckboxes1">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ fontWeight: '500', fontSize: '18px', paddingTop: '15px' }} align="left" valign="bottom">
                                                                                    Internal
                                                                                </td>
                                                                                <td align="right">

                                                                                </td>
                                                                                <td style={{ borderLeft: '0' }} align="right" valign="bottom">
                                                                                    <strong style={{ fontWeight: '500' }}>OD</strong>&nbsp;nl&nbsp;
                                                                                </td>
                                                                                <td align="left" valign="bottom">

                                                                                </td>
                                                                                <td align="right" valign="bottom" style={{ borderLeft: '0' }}>
                                                                                    <strong style={{ fontWeight: '500' }}>OS</strong>&nbsp;nl&nbsp;
                                                                                </td>
                                                                                <td align="right">

                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalDilated: !this.state.InternalDilated, examSaved: false }) }}
                                                                                        checked={this.state.InternalDilated}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    dilated
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                InternalODSelectAll: !this.state.InternalODSelectAll,
                                                                                                InternalODDisc: !this.state.InternalODSelectAll,
                                                                                                InternalODMacula: !this.state.InternalODSelectAll,
                                                                                                InternalODVessels: !this.state.InternalODSelectAll,
                                                                                                InternalODFundus: !this.state.InternalODSelectAll,
                                                                                                InternalODPeriphery: !this.state.InternalODSelectAll, examSaved: false
                                                                                            })
                                                                                        }}
                                                                                        checked={this.state.InternalODSelectAll}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left" style={{ fontWeight: '500' }}>
                                                                                    Select All
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                InternalOSSelectAll: !this.state.InternalOSSelectAll,
                                                                                                InternalOSDisc: !this.state.InternalOSSelectAll,
                                                                                                InternalOSMacula: !this.state.InternalOSSelectAll,
                                                                                                InternalOSVessels: !this.state.InternalOSSelectAll,
                                                                                                InternalOSFundus: !this.state.InternalOSSelectAll,
                                                                                                InternalOSPeriphery: !this.state.InternalOSSelectAll, examSaved: false
                                                                                            })
                                                                                        }}
                                                                                        checked={this.state.InternalOSSelectAll}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left" style={{ fontWeight: '500' }}>
                                                                                    Select All
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalUndilated: !this.state.InternalUndilated, examSaved: false }) }}
                                                                                        checked={this.state.InternalUndilated}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    undilated
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalODDisc: !this.state.InternalODDisc, examSaved: false }) }}
                                                                                        checked={this.state.InternalODDisc}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Disc
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalOSDisc: !this.state.InternalOSDisc, examSaved: false }) }}
                                                                                        checked={this.state.InternalOSDisc}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Disc
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalBio: !this.state.InternalBio, examSaved: false }) }}
                                                                                        checked={this.state.InternalBio}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    BIO
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalODMacula: !this.state.InternalODMacula, examSaved: false }) }}
                                                                                        checked={this.state.InternalODMacula}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Macula
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalOSMacula: !this.state.InternalOSMacula, examSaved: false }) }}
                                                                                        checked={this.state.InternalOSMacula}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Macula
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ Internal20D: !this.state.Internal20D, examSaved: false }) }}
                                                                                        checked={this.state.Internal20D}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    20D
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalODVessels: !this.state.InternalODVessels, examSaved: false }) }}
                                                                                        checked={this.state.InternalODVessels}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Vessels
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalOSVessels: !this.state.InternalOSVessels, examSaved: false }) }}
                                                                                        checked={this.state.InternalOSVessels}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Vessels
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ Internal90D: !this.state.Internal90D, examSaved: false }) }}
                                                                                        checked={this.state.Internal90D}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    90D
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalODFundus: !this.state.InternalODFundus, examSaved: false }) }}
                                                                                        checked={this.state.InternalODFundus}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Fundus
                                                                                </td>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalOSFundus: !this.state.InternalOSFundus, examSaved: false }) }}
                                                                                        checked={this.state.InternalOSFundus}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Fundus
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ Internal78D: !this.state.Internal78D, examSaved: false }) }}
                                                                                        checked={this.state.Internal78D}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    78D
                                                                                </td>
                                                                                <td align="right">
                                                                                    intact 360 <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalODPeriphery: !this.state.InternalODPeriphery, examSaved: false }) }}
                                                                                        checked={this.state.InternalODPeriphery}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Periphery
                                                                                </td>
                                                                                <td align="right">
                                                                                    intact 360 <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalOSPeriphery: !this.state.InternalOSPeriphery, examSaved: false }) }}
                                                                                        checked={this.state.InternalOSPeriphery}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    Periphery
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalPhotos: !this.state.InternalPhotos, examSaved: false }) }}
                                                                                        checked={this.state.InternalPhotos}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left">
                                                                                    photos
                                                                                </td>
                                                                                <td align="right">
                                                                                    C/D
                                                                                </td>
                                                                                <td align="left">
                                                                                    <select className="form-control width-fit-content"
                                                                                        onChange={(e) => this.setState({ ODCD: e.target.value, a6: false, examSaved: false })}
                                                                                        value={this.state.ODCD}
                                                                                    >
                                                                                        <option key={0} value={0}>---</option>
                                                                                        {this.state.examCD.map((item, i) =>
                                                                                            <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                        )}
                                                                                    </select>
                                                                                </td>
                                                                                <td align="right">
                                                                                    C/D
                                                                                </td>
                                                                                <td align="left">
                                                                                    <select
                                                                                        className="form-control width-fit-content"
                                                                                        onChange={(e) => this.setState({ OSCD: e.target.value, a6: false, examSaved: false })}
                                                                                        value={this.state.OSCD}
                                                                                    >
                                                                                        <option key={0} value={0}>---</option>
                                                                                        {this.state.examCD.map((item, i) =>
                                                                                            <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                        )}
                                                                                    </select>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td align="right" valign="top">
                                                                                    <Input
                                                                                        type='checkbox'
                                                                                        onChange={(e) => { this.setState({ InternalDirect: !this.state.InternalDirect, examSaved: false }) }}
                                                                                        checked={this.state.InternalDirect ?? false}
                                                                                        className="form-control-checkbox"
                                                                                    />
                                                                                </td>
                                                                                <td align="left" valign="top">
                                                                                    direct
                                                                                </td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td align="left" valign="bottom">
                                                                    <textarea
                                                                        onChange={(e) => {
                                                                            this.setState({ InternalNotes: e.target.value, examSaved: false });
                                                                        }}
                                                                        placeholder="Notes"
                                                                        value={this.state.InternalNotes}
                                                                        style={{ width: '24vw', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table className="gridTable" style={{ marginTop: '15px', marginBottom: '10px', width: 'fit-content' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Impressions
                                                                                </th>
                                                                                <th>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.impressions?.length > 0 ?
                                                                                this.state.impressions?.map((item, i) =>
                                                                                    <tr>
                                                                                        <td id={item.id}>{item.name}</td>
                                                                                        <td className="deleteUploadCell">
                                                                                            {this.state.disabled ? null : <IoTrashOutline className="print-hide" color="#000" size={21} style={{ cursor: 'pointer' }} onClick={() => this.deleteImpression(item.id)} />}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                                :
                                                                                <tr>
                                                                                    <td colSpan={2} style={{ fontWeight: '500', textAlign: 'middle' }}>No Results Found</td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ display: 'flex', flexDirection: 'row', width: 'fit-content', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                                    <Button
                                                                        color='primary'
                                                                        onClick={() => this.setState({ showImpressionModal: true })}
                                                                        style={{ whiteSpace: 'nowrap', }}
                                                                        disabled={this.state.loading || this.state.disabled ? true : false}
                                                                    >
                                                                        Add Impression
                                                                    </Button>
                                                                    <select className="form-control width-fit-content print-hide"
                                                                        disabled={this.state.disabled ? true : false}
                                                                        style={{ marginLeft: '10px' }}
                                                                        onChange={(e) => {
                                                                            this.setState({ examSaved: false });
                                                                            var index = e.nativeEvent.target.selectedIndex;

                                                                            this.addImpression(undefined, e.nativeEvent.target[index].text)
                                                                        }}
                                                                        value={this.state.quickImpression}
                                                                    >
                                                                        <option key={0} value={0}>---Impression Quick Add---</option>
                                                                        <option key={4} value={4}>H5201 - Hyperopia, right eye</option>
                                                                        <option key={5} value={5}>H5202 - Hyperopia, left eye</option>
                                                                        <option key={6} value={6}>H5203 - Hyperopia, bilateral</option>
                                                                        <option key={1} value={1}>H5211 - Myopia, right eye</option>
                                                                        <option key={2} value={2}>H5212 - Myopia, left eye</option>
                                                                        <option key={3} value={3}>H5213 - Myopia, bilateral</option>
                                                                        <option key={13} value={13}>H5231 - Anisometropia</option>
                                                                        <option key={10} value={10}>H52221 - Regular astigmatism, right eye</option>
                                                                        <option key={11} value={11}>H52222 - Regular astigmatism, left eye</option>
                                                                        <option key={12} value={12}>H52223 - Regular astigmatism, bilateral</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Input
                                                                        type='checkbox'
                                                                        onChange={(e) => {
                                                                            this.setState({ Referral: !this.state.Referral, examSaved: false });
                                                                            setTimeout(function () {
                                                                                window.scrollTo(0, document.body.scrollHeight);
                                                                            }, 250);
                                                                        }}
                                                                        checked={this.state.Referral}
                                                                        className="form-control-checkbox"
                                                                        style={{ marginBottom: '0', marginTop: '0' }}
                                                                    />&nbsp;<label style={{ fontWeight: '500', fontSize: '16px', margin: '0', padding: '0', marginTop: '15px', marginBottom: '15px' }}> Referral</label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {this.state.Referral ?
                                                                        <table className="examTable2">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: '1vw', whiteSpace: 'nowrap' }}>
                                                                                        Reason for Referral
                                                                                    </td>
                                                                                    <td align="left">
                                                                                        <Input
                                                                                            type='text'
                                                                                            onChange={(e) => {
                                                                                                this.setState({ ReasonForReferral: e.target.value, examSaved: false });
                                                                                            }}
                                                                                            placeholder=''
                                                                                            className={'form-control'}
                                                                                            value={this.state.ReasonForReferral}
                                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td align="right">
                                                                                        Provider
                                                                                    </td>
                                                                                    <td align="left">
                                                                                        <select className="form-control"
                                                                                            onChange={(e) => this.setState({ Provider: e.target.value, a6: false, examSaved: false })}
                                                                                            value={this.state.Provider}
                                                                                        >
                                                                                            <option key={0} value={0}>---</option>
                                                                                            {this.state.examProvider.map((item, i) =>
                                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                    <td align="left">
                                                                                        <Input
                                                                                            type='checkbox'
                                                                                            onChange={(e) => { this.setState({ UrgentEmergency: !this.state.UrgentEmergency, examSaved: false }) }}
                                                                                            checked={this.state.UrgentEmergency}
                                                                                            className="form-control-checkbox"
                                                                                            style={{ marginBottom: '0', marginTop: '0' }}
                                                                                        />&nbsp;Urgent / Emergency
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        : null}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">
                                                                    <Input
                                                                        type='checkbox'
                                                                        onChange={(e) => { this.setState({ DiabetesCommunicationReport: !this.state.DiabetesCommunicationReport, examSaved: false }) }}
                                                                        checked={this.state.DiabetesCommunicationReport}
                                                                        className="form-control-checkbox"
                                                                        style={{ marginBottom: '0', marginTop: '0' }}
                                                                    />&nbsp;Diabetes Communication Report
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%', marginLeft: '1px' }}>
                                        <tbody>
                                            <tr>
                                                <td align="left">
                                                    <Input
                                                        type='checkbox'
                                                        onChange={(e) => {
                                                            this.setState({ GlassesNeeded: !this.state.GlassesNeeded, examSaved: false });
                                                            setTimeout(function () {
                                                                window.scrollTo(0, document.body.scrollHeight);
                                                            }, 250);
                                                        }}
                                                        checked={this.state.GlassesNeeded}
                                                        className="form-control-checkbox"
                                                        style={{ marginBottom: '0', marginTop: '0' }}
                                                    />&nbsp;<label style={{ fontWeight: '500', fontSize: '16px', margin: '0', padding: '0', marginBottom: '15px' }}> Glasses Needed</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {this.state.GlassesNeeded ?
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} style={{ fontWeight: '500' }}>
                                                                        Final SRx
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OD
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxODSphere: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxODSphere}
                                                                        >
                                                                            <option key={0} value={0}>---sphere---</option>
                                                                            {this.state.examSphere.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxODCyl: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxODCyl}
                                                                        >
                                                                            <option key={0} value={0}>---cyl---</option>
                                                                            {this.state.examCyl.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='text'
                                                                            onChange={(e) => {
                                                                                this.setState({ FinalSRxODAxis: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder='axis'
                                                                            maxLength={3}
                                                                            onBlur={() => this.setState({
                                                                                FinalSRxODAxis: userFunctions.PadWithZeros(this.state.FinalSRxODAxis, 3)
                                                                            })}
                                                                            className={'form-control static-width-6vw'}
                                                                            value={this.state.FinalSRxODAxis}
                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxODAdd: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxODAdd}
                                                                        >
                                                                            <option key={0} value={0}>---add---</option>
                                                                            {this.state.examAdd.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxODPrism: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxODPrism}
                                                                        >
                                                                            <option key={0} value={0}>---prism---</option>
                                                                            {this.state.examPrism.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxODBase: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxODBase}
                                                                        >
                                                                            <option key={0} value={0}>---base---</option>
                                                                            {this.state.examBase.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="right">
                                                                        OS
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxOSSphere: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxOSSphere}
                                                                        >
                                                                            <option key={0} value={0}>---sphere---</option>
                                                                            {this.state.examSphere.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxOSCyl: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxOSCyl}
                                                                        >
                                                                            <option key={0} value={0}>---cyl---</option>
                                                                            {this.state.examCyl.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type='text'
                                                                            onChange={(e) => {
                                                                                this.setState({ FinalSRxOSAxis: e.target.value, examSaved: false });
                                                                            }}
                                                                            placeholder='axis'
                                                                            maxLength={3}
                                                                            onBlur={() => this.setState({
                                                                                FinalSRxOSAxis: userFunctions.PadWithZeros(this.state.FinalSRxOSAxis, 3)
                                                                            })}
                                                                            className={'form-control static-width-6vw'}
                                                                            value={this.state.FinalSRxOSAxis}
                                                                            style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxOSAdd: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxOSAdd}
                                                                        >
                                                                            <option key={0} value={0}>---add---</option>
                                                                            {this.state.examAdd.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxOSPrism: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxOSPrism}
                                                                        >
                                                                            <option key={0} value={0}>---prism---</option>
                                                                            {this.state.examPrism.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-control static-width-6vw"
                                                                            onChange={(e) => this.setState({ FinalSRxOSBase: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.FinalSRxOSBase}
                                                                        >
                                                                            <option key={0} value={0}>---base---</option>
                                                                            {this.state.examBase.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ paddingTop: '10px' }}>
                                                                    <td style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                                                                        Wear Schedule
                                                                    </td>
                                                                    <td colSpan={6}>
                                                                        <select className="form-control width-fit-content"
                                                                            style={{ width: 'fit-content' }}
                                                                            onChange={(e) => this.setState({ WearSchedule: e.target.value, a6: false, examSaved: false })}
                                                                            value={this.state.WearSchedule}
                                                                        >
                                                                            <option key={0} value={0}>---Select---</option>
                                                                            {this.state.examWearSchedule.map((item, i) =>
                                                                                <option key={i + 1} value={item.id}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ paddingTop: '10px' }}>
                                                                    <td align="left">
                                                                        <Input
                                                                            type='checkbox'
                                                                            onChange={(e) => {
                                                                                this.setState({ TwoPairGlasses: !this.state.TwoPairGlasses, examSaved: false });
                                                                            }}
                                                                            checked={this.state.TwoPairGlasses}
                                                                            className="form-control-checkbox"
                                                                            style={{ marginBottom: '0', marginTop: '0' }}
                                                                        />&nbsp;<label style={{ fontWeight: '400', margin: '0', padding: '0', whiteSpace: 'nowrap', marginBottom: '15px' }}> 2 Pair of glasses</label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        : null}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <textarea
                                                        onChange={(e) => {
                                                            this.setState({ FinalNotes: e.target.value, examSaved: false });
                                                        }}
                                                        placeholder="Notes"
                                                        value={this.state.FinalNotes}
                                                        style={{ width: '24vw', height: '15vh', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '10px' }}>
                                                    Doctor
                                                    &nbsp;<select
                                                        className="form-control width-fit-content"
                                                        onChange={(e) => {
                                                            if (e.target.value == 0) {
                                                                this.setState({ DoctorUserId: undefined, doctorSig: './img/blank.png', examSaved: false })
                                                            }
                                                            else {
                                                                var index = this.state.doctors.findIndex(f => f.id === e.target.value);
                                                                this.setState({
                                                                    DoctorUserId: e.target.value,
                                                                    a6: false, examSaved: false,
                                                                });
                                                                if (index != -1) {
                                                                    this.setState({ doctorSig: this.state.doctors[index].signatureURL, examSaved: false })
                                                                }
                                                            }
                                                        }
                                                        }
                                                        value={this.state.DoctorUserId}
                                                    >
                                                        <option key={0} value={0}>---Doctor---</option>
                                                        {this.state.doctors?.map((item, i) =>
                                                            <option key={i + 1} value={item.id}>{item.name}</option>
                                                        )}
                                                    </select>
                                                    &nbsp;<img src={this.state.doctorSig} style={{ width: '194px', height: '49px', border: '0', outline: '0' }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ display: 'flex', width: '100%', fontWeight: '500', whiteSpace: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>
                                                    Return in 1 Year
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '20px', paddingTop: '20px' }}>
                                                    <Button
                                                        color='primary'
                                                        onClick={async () => await this.saveExam(true)}
                                                        style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                                                        disabled={this.state.loading || this.state.disabled ? true : false}
                                                    >
                                                        Exam Complete
                                                    </Button>
                                                    <Button
                                                        color='primary'
                                                        onClick={async () => window.print()}
                                                        style={{ whiteSpace: 'nowrap', }}
                                                        disabled={this.state.loading ? true : false || this.state.disabled}
                                                    >
                                                        Print Exam
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            : null
                    }
                </Container >

                <div>
                    <Modal
                        isOpen={this.state.showImpressionModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showImpressionModal: false })}
                        className="et-modal"
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Add Impression</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30vh', overflow: 'auto' }}>
                            <table className="gridTable" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>
                                            Exam Impressions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.impressions?.map((item, i) =>
                                        <tr>
                                            <td key={i + 1} id={item.id}>{item.name}</td>
                                            <td className="deleteUploadCell">
                                                <IoTrashOutline color="#000" size={21} style={{ cursor: 'pointer' }} onClick={async () => await this.deleteImpression(item.id)} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <label style={{ marginRight: '5px', marginBottom: '0' }}>Search</label>
                            <input
                                type="text"
                                className="form-control min-width-textinput"
                                placeholder="Search Impressions"
                                onChange={(e) => this.setState({ searchWord: e.currentTarget.value })}
                                onKeyPress={(e) => this.handleKeyPress(e)}
                                autoFocus>
                            </input>
                            <Button
                                color='primary'
                                onClick={async () => await this.getImpressionsByKeyword(this.state.searchWord)}
                                style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}
                                disabled={this.state.loading ? true : false}
                                className="min-width-button"
                            >
                                Search
                            </Button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', marginBottom: '10px', justifyContent: 'flex-end' }}>
                            <label style={{ marginRight: '5px', marginBottom: '0' }}>Add New</label>
                            <input
                                type="text"
                                className="form-control min-width-textinput"
                                placeholder="Impression Name"
                                onChange={(e) => this.setState({ impressionName: e.currentTarget.value })}
                                disabled={!this.state.enableManualAddImpression}
                            >
                            </input>
                            <Button
                                color='primary'
                                className="min-width-button"
                                onClick={async () => await this.addImpression(undefined, undefined)}
                                style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}
                                disabled={!this.state.enableManualAddImpression}
                            >
                                Add
                            </Button>
                        </div>
                        {this.state.showImpressionSearchGrid ?
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '31vh', overflow: 'auto' }}>
                                <table className="gridTable" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>
                                                Impressions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.impressionsSearch.map((item, i) =>
                                            <tr>
                                                <td>
                                                    <div onClick={() => { this.addImpression(item.id, undefined) }} style={{ cursor: 'pointer', zIndex: '9999', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                        <BsPlusCircleFill color="#00cc00" size={22} />
                                                    </div>
                                                </td>
                                                <td key={i + 1}>{item.name}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div> : null}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                            <button
                                color='primary'
                                className="btn btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={this.state.loading ? true : false}
                                onClick={() => {
                                    this.setState({
                                        impressionsSearch: [],
                                        searchWord: '',
                                        impressionName: '',
                                        showImpressionModal: false,
                                        enableManualAddImpression: false,
                                        showImpressionSearchGrid: false
                                    });
                                }}>
                                Close
                            </button>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={this.state.showSaveModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showSaveModal: false })}
                        className="et-modal"
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>You have unsaved changes. Save now?</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', background: '#ffffff00' }}>
                            <table style={{ marginTop: '10px', marginBottom: '10px', background: '#ffffff00' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <button
                                                color='primary'
                                                className="btn btn-primary"
                                                style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                                                disabled={this.state.loading ? true : false}
                                                onClick={() => { this.setState({ examSaved: true, showSaveModal: false }) }}>
                                                No
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                color='primary'
                                                className="btn btn-primary"
                                                style={{ whiteSpace: 'nowrap' }}
                                                disabled={this.state.loading ? true : false}
                                                onClick={() => {
                                                    if (this.state.showExam) {
                                                        //this.savePreTest(false);
                                                        this.saveExam(true);
                                                    }
                                                    else {
                                                        this.savePreTest(true);
                                                    }
                                                }}>
                                                Yes
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>

                </div>
            </div >
        )
    }

    async openReferral(id) {
        const token = await authService.getAccessToken();

        await fetch('api/Exam/GetReferralPdf?examId=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                window.open(URL.createObjectURL(data), "_blank");
            })
    }

    async saveExam(examComplete) {
        if (this.state.impressions?.length > 0) {
            if (this.state.DoctorUserId?.length > 0) {
                this.setState({ loading: true });
                const token = await authService.getAccessToken();
                const userId = await authService.getUserId();

                var data = {
                    LoggedInUserId: userId,
                    Id: this.state.examId,
                    UserId: this.state.DoctorUserId,
                    ExamDate: this.state.examDate,
                    StudentId: this.state.studentId,
                    DistanceBlurOD: this.state.DistanceBlurOD,
                    DistanceBlurOS: this.state.DistanceBlurOS,
                    DistanceBlurOU: this.state.DistanceBlurOU,
                    DistanceBlurConstant: this.state.DistanceBlurConstant,
                    DistanceBlurIntermittent: this.state.DistanceBlurIntermittent,
                    DistanceBlurNone: this.state.DistanceBlurNone,
                    NearBlurOD: this.state.NearBlurOD,
                    NearBlurOS: this.state.NearBlurOS,
                    NearBlurOU: this.state.NearBlurOU,
                    NearBlurConstant: this.state.NearBlurConstant,
                    NearBlurIntermittent: this.state.NearBlurIntermittent,
                    NearBlurNone: this.state.NearBlurNone,
                    PupilsPERRL: this.state.PupilsPERRL,
                    PupilsAPD: this.state.PupilsAPD,
                    ChiefComplaintNotes: userFunctions.ValueOrNull(this.state.ChiefComplaintNotes, true),
                    EnteringAcuitiesDistanceSCOD: userFunctions.ValueOrNull(this.state.EnteringAcuitiesDistanceSCOD, true),
                    EnteringAcuitiesDistanceCCOD: userFunctions.ValueOrNull(this.state.EnteringAcuitiesDistanceCCOD, true),
                    EnteringAcuitiesDistanceSCOS: userFunctions.ValueOrNull(this.state.EnteringAcuitiesDistanceSCOS, true),
                    EnteringAcuitiesDistanceCCOS: userFunctions.ValueOrNull(this.state.EnteringAcuitiesDistanceCCOS, true),
                    EnteringAcuitiesDistanceSCOU: userFunctions.ValueOrNull(this.state.EnteringAcuitiesDistanceSCOU, true),
                    EnteringAcuitiesDistanceCCOU: userFunctions.ValueOrNull(this.state.EnteringAcuitiesDistanceCCOU, true),
                    AcuitiesNotes: userFunctions.ValueOrNull(this.state.AcuitiesNotes, true),
                    CTDistance: userFunctions.ValueOrNull(this.state.CTDistance, true),
                    PNCTTN: this.state.PNCTTN,
                    CTNear: userFunctions.ValueOrNull(this.state.CTNear, true),
                    NPCCM: this.state.NPCCM,
                    EnteringAcuitiesNearSCOD: userFunctions.ValueOrNull(this.state.EnteringAcuitiesNearSCOD, true),
                    EnteringAcuitiesNearCCOD: userFunctions.ValueOrNull(this.state.EnteringAcuitiesNearCCOD, true),
                    EnteringAcuitiesNearSCOS: userFunctions.ValueOrNull(this.state.EnteringAcuitiesNearSCOS, true),
                    EnteringAcuitiesNearCCOS: userFunctions.ValueOrNull(this.state.EnteringAcuitiesNearCCOS, true),
                    EnteringAcuitiesNearSCOU: userFunctions.ValueOrNull(this.state.EnteringAcuitiesNearSCOU, true),
                    EnteringAcuitiesNearCCOU: userFunctions.ValueOrNull(this.state.EnteringAcuitiesNearCCOU, true),
                    EOMs: this.state.EOMs,
                    EOMsNL: userFunctions.ValueOrNull(this.state.EOMsNL, true),
                    CVF: this.state.CVF,
                    CVFNL: userFunctions.ValueOrNull(this.state.CVFNL, true),
                    GridDropdown1: userFunctions.ValueOrNull(this.state.GridDropdown1, true),
                    GridDropdown2: userFunctions.ValueOrNull(this.state.GridDropdown2, true),
                    GridDropdown3: userFunctions.ValueOrNull(this.state.GridDropdown3, true),
                    GridDropdown4: userFunctions.ValueOrNull(this.state.GridDropdown4, true),

                    CVFOS: this.state.CVFOS,
                    CVFNLOS: userFunctions.ValueOrNull(this.state.CVFNLOS, true),
                    OSGridDropdown1: userFunctions.ValueOrNull(this.state.OSGridDropdown1, true),
                    OSGridDropdown2: userFunctions.ValueOrNull(this.state.OSGridDropdown2, true),
                    OSGridDropdown3: userFunctions.ValueOrNull(this.state.OSGridDropdown3, true),
                    OSGridDropdown4: userFunctions.ValueOrNull(this.state.OSGridDropdown4, true),

                    RetinoscopyODSphere: userFunctions.ValueOrNull(this.state.RetinoscopyODSphere, true),
                    RetinoscopyODCyl: userFunctions.ValueOrNull(this.state.RetinoscopyODCyl, true),
                    RetinoscopyODAxis: userFunctions.ValueOrNull(this.state.RetinoscopyODAxis, true),
                    RetinoscopyOSSphere: userFunctions.ValueOrNull(this.state.RetinoscopyOSSphere, true),
                    RetinoscopyOSCyl: userFunctions.ValueOrNull(this.state.RetinoscopyOSCyl, true),
                    RetinoscopyOSAxis: userFunctions.ValueOrNull(this.state.RetinoscopyOSAxis, true),
                    ManifestODSphere: userFunctions.ValueOrNull(this.state.ManifestODSphere, true),
                    ManifestODCyl: userFunctions.ValueOrNull(this.state.ManifestODCyl, true),
                    ManifestODAxis: userFunctions.ValueOrNull(this.state.ManifestODAxis, true),
                    ManifestOSSphere: userFunctions.ValueOrNull(this.state.ManifestOSSphere, true),
                    ManifestOSCyl: userFunctions.ValueOrNull(this.state.ManifestOSCyl, true),
                    ManifestOSAxis: userFunctions.ValueOrNull(this.state.ManifestOSAxis, true),
                    ManifestDistanceOU: userFunctions.ValueOrNull(this.state.ManifestDistanceOU, true),
                    ManifestNearOU: userFunctions.ValueOrNull(this.state.ManifestNearOU, true),
                    ManifestNotes: userFunctions.ValueOrNull(this.state.ManifestNotes, true),
                    ManifestOD20: userFunctions.ValueOrNull(this.state.ManifestOD20, true),
                    ManifestADD: userFunctions.ValueOrNull(this.state.ManifestADD, true),
                    ManifestNearOU20: userFunctions.ValueOrNull(this.state.ManifestNearOU20, true),
                    ManifestOS20: userFunctions.ValueOrNull(this.state.ManifestOS20, true),
                    ManifestNRA: userFunctions.ValueOrNull(this.state.ManifestNRA, true),
                    ManifestPRA: userFunctions.ValueOrNull(this.state.ManifestPRA, true),
                    SlitLampODSelectAll: this.state.SlitLampODSelectAll,
                    SlitLampOSSelectAll: this.state.SlitLampOSSelectAll,
                    SlitLampODII: this.state.SlitLampODII,
                    SlitLampOSII: this.state.SlitLampOSII,
                    SlitLampODConj: this.state.SlitLampODConj,
                    SlitLampOSConj: this.state.SlitLampOSConj,
                    SlitLampODCornea: this.state.SlitLampODCornea,
                    SlitLampOSCornea: this.state.SlitLampOSCornea,
                    SlitLampODAC: this.state.SlitLampODAC,
                    SlitLampOSAC: this.state.SlitLampOSAC,
                    SlitLampODIris: this.state.SlitLampODIris,
                    SlitLampOSIris: this.state.SlitLampOSIris,
                    SlitLampODLens: this.state.SlitLampODLens,
                    SlitLampOSLens: this.state.SlitLampOSLens,
                    SlitLampNotes: userFunctions.ValueOrNull(this.state.SlitLampNotes, true),
                    IOPOD: userFunctions.ValueOrNull(this.state.IOPOD, true),
                    TaODiCare: this.state.TaODiCare,
                    IOPOS: userFunctions.ValueOrNull(this.state.IOPOS, true),
                    TaOSFT: this.state.TaOSFT,
                    TaOSFTText: userFunctions.ValueOrNull(this.state.TaOSFTText, true),
                    TaNotes: userFunctions.ValueOrNull(this.state.TaNotes, true),
                    Dilation: this.state.Dilation,
                    DilationDropsUsed: userFunctions.ValueOrNull(this.state.DilationDropsUsed, true),
                    DilationTime: userFunctions.ValueOrNull(this.state.DilationTime, true),
                    DilationNotes: userFunctions.ValueOrNull(this.state.DilationNotes, true),
                    DilationOD: this.state.DilationOD,
                    DilationOS: this.state.DilationOS,
                    DilationOU: this.state.DilationOU,
                    DilatedRefractionODSphere: userFunctions.ValueOrNull(this.state.DilatedRefractionODSphere, true),
                    DilatedRefractionODCyl: userFunctions.ValueOrNull(this.state.DilatedRefractionODCyl, true),
                    DilatedRefractionODAxis: userFunctions.ValueOrNull(this.state.DilatedRefractionODAxis, true),
                    DilatedRefractionOD20: userFunctions.ValueOrNull(this.state.DilatedRefractionOD20, true),
                    DilatedRefractionOSSphere: userFunctions.ValueOrNull(this.state.DilatedRefractionOSSphere, true),
                    DilatedRefractionOSCyl: userFunctions.ValueOrNull(this.state.DilatedRefractionOSCyl, true),
                    DilatedRefractionOSAxis: userFunctions.ValueOrNull(this.state.DilatedRefractionOSAxis, true),
                    DilatedRefractionOS20: userFunctions.ValueOrNull(this.state.DilatedRefractionOS20, true),
                    InternalDilated: this.state.InternalDilated,
                    InternalODSelectAll: this.state.InternalODSelectAll,
                    InternalOSSelectAll: this.state.InternalOSSelectAll,
                    InternalUndilated: this.state.InternalUndilated,
                    InternalODDisc: this.state.InternalODDisc,
                    InternalOSDisc: this.state.InternalOSDisc,
                    InternalBio: this.state.InternalBio,
                    InternalODMacula: this.state.InternalODMacula,
                    InternalOSMacula: this.state.InternalOSMacula,
                    Internal20D: this.state.Internal20D,
                    InternalODVessels: this.state.InternalODVessels,
                    InternalOSVessels: this.state.InternalOSVessels,
                    Internal90D: this.state.Internal90D,
                    InternalODFundus: this.state.InternalODFundus,
                    InternalOSFundus: this.state.InternalOSFundus,
                    Internal78D: this.state.Internal78D,
                    InternalODPeriphery: this.state.InternalODPeriphery,
                    InternalOSPeriphery: this.state.InternalOSPeriphery,
                    InternalPhotos: this.state.InternalPhotos,
                    ODCD: userFunctions.ValueOrNull(this.state.ODCD, true),
                    OSCD: userFunctions.ValueOrNull(this.state.OSCD, true),
                    Referral: this.state.Referral,
                    ReasonForReferral: userFunctions.ValueOrNull(this.state.ReasonForReferral, true),
                    Provider: userFunctions.ValueOrNull(this.state.Provider, true),
                    UrgentEmergency: this.state.UrgentEmergency,
                    DiabetesCommunicationReport: this.state.DiabetesCommunicationReport,
                    GlassesNeeded: this.state.GlassesNeeded,
                    FinalSRxODSphere: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxODSphere, true) : null,
                    FinalSRxODCyl: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxODCyl, true) : null,
                    FinalSRxODAxis: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxODAxis, true) : null,
                    FinalSRxODAdd: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxODAdd, true) : null,
                    FinalSRxODPrism: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxODPrism, true) : null,
                    FinalSRxODBase: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxODBase, true) : null,
                    FinalSRxOSSphere: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxOSSphere, true) : null,
                    FinalSRxOSCyl: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxOSCyl, true) : null,
                    FinalSRxOSAxis: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxOSAxis, true) : null,
                    FinalSRxOSAdd: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxOSAdd, true) : null,
                    FinalSRxOSPrism: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxOSPrism, true) : null,
                    FinalSRxOSBase: this.state.GlassesNeeded ? userFunctions.ValueOrNull(this.state.FinalSRxOSBase, true) : null,
                    WearSchedule: userFunctions.ValueOrNull(this.state.WearSchedule, true),
                    CTNotes: userFunctions.ValueOrNull(this.state.CTNotes, true),
                    RetinoscopyNotes: userFunctions.ValueOrNull(this.state.RetinoscopyNotes, true),
                    InternalNotes: userFunctions.ValueOrNull(this.state.InternalNotes, true),
                    FinalNotes: userFunctions.ValueOrNull(this.state.FinalNotes, true),
                    TwoPairGlasses: this.state.TwoPairGlasses,
                    InternalDirect: this.state.InternalDirect ?? false,
                    ExamComplete: examComplete
                }

                await fetch('api/Exam/SaveExam', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
                })
                    .then(async (response) => {
                        return response.json()
                    })
                    .then((data) => {
                        if (data && data !== null && data !== undefined) {
                            this.setState({ toastMessage: 'Exam saved successfully' });
                            this.setState({ showToast: true, loading: false, examSaved: true, showSaveModal: false });

                            this.hideToast();
                        }
                        else {
                            this.setState({ toastErrorMessage: 'There was an error saving the exam', showToastError: true });
                            this.hideToast();
                        }
                    })
                    .catch((error) => {
                        console.log('error in SaveExam - ' + error);
                        this.setState({ loading: false });
                        this.setState({ toastErrorMessage: 'There was an error saving the exam', showToastError: true });
                        this.hideToast();
                    });
            }
            else {
                this.setState({ toastErrorMessage: 'A doctor selection is required', showToastError: true });
                this.hideToast();
            }
        }
        else {
            this.setState({ toastErrorMessage: 'At least one Impression is required', showToastError: true });
            this.hideToast();
        }
    }

    async getStudentUploads(studentId) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch("api/upload/studentfiles?studentId=" + studentId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ studentUploads: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in getStudentUploads - ' + error.message);
                this.setState({ loading: false });
            });

    }

    async getScreeningGridForExam(studentId) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch("api/student/getScreeningGridForExam?studentId=" + studentId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ screenings: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in getScreeningGridForExam - ' + error.message);
                this.setState({ loading: false });
            });

    }

    async savePreTest(showToast) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        //alert(userFunctions.ValueOrNull(this.state.examId, true))

        var data = {
            Id: this.state.examId,
            ExamDate: userFunctions.ValueOrNull(this.state.examDate, true),
            StudentId: userFunctions.ValueOrNull(this.state.studentId, true),
            SiteId: userFunctions.ValueOrNull(this.state.siteId, true),
            HSRxODSphere: userFunctions.ValueOrNull(this.state.HSRxODSphere, true),
            HSRxODCyl: userFunctions.ValueOrNull(this.state.HSRxODCyl, true),
            HSRxODAxis: userFunctions.ValueOrNull(this.state.HSRxODAxis, true),
            HSRxODAdd: userFunctions.ValueOrNull(this.state.HSRxODAdd, true),
            HSRxOSSphere: userFunctions.ValueOrNull(this.state.HSRxOSSphere, true),
            HSRxOSCyl: userFunctions.ValueOrNull(this.state.HSRxOSCyl, true),
            HSRxOSAxis: userFunctions.ValueOrNull(this.state.HSRxOSAxis, true),
            HSRxOSAdd: userFunctions.ValueOrNull(this.state.HSRxOSAdd, true),
            AutorefractorODSphere: userFunctions.ValueOrNull(this.state.AutorefractorODSphere, true),
            AutorefractorODCyl: userFunctions.ValueOrNull(this.state.AutorefractorODCyl, true),
            AutorefractorODAxis: userFunctions.ValueOrNull(this.state.AutorefractorODAxis, true),
            AutorefractorOSSphere: userFunctions.ValueOrNull(this.state.AutorefractorOSSphere, true),
            AutorefractorOSCyl: userFunctions.ValueOrNull(this.state.AutorefractorOSCyl, true),
            AutorefractorOSAxis: userFunctions.ValueOrNull(this.state.AutorefractorOSAxis, true),
            Stereo3Shapes: userFunctions.ValueOrNull(this.state.Stereo3Shapes, false),
            Stereo8Randot: userFunctions.ValueOrNull(this.state.Stereo8Randot, false),
            Color12: userFunctions.ValueOrNull(this.state.Color12, false),
            DistancePDOD: userFunctions.ValueOrNull(this.state.DistancePDOD, true),
            DistancePDOS: userFunctions.ValueOrNull(this.state.DistancePDOS, true),
            DistancePDTotal: userFunctions.ValueOrNull(this.state.DistanceTotal, true),
            OcularHistory: userFunctions.ValueOrNull(this.state.OcularHistory, true),
            Allergies: userFunctions.ValueOrNull(this.state.Allergies, true),
            MedicalHistory: userFunctions.ValueOrNull(this.state.MedicalHistory, true),
            PreTestComments: userFunctions.ValueOrNull(this.state.PretestComments, true),
            IEP504Plan: this.state.IEP504Plan,
            PreferredName: userFunctions.ValueOrNull(this.state.preferredName, true)
        }



        await fetch('api/Exam/SaveExamPreTest', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (showToast) {
                    this.setState({ toastMessage: 'Exam Pre-Test saved successfully', examSaved: true, showSaveModal: false });
                    this.setState({ showToast: true, loading: false, examId: data });

                    this.hideToast();
                }
                else {
                    this.setState({ loading: false, examId: data, examSaved: true, showSaveModal: false });
                }
            })
            .catch((error) => {
                console.log('error in SavePreExam - ' + error);
                this.setState({ loading: false });
            });
    }

    async getSites() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSites', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ sites: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getSites - ' + error.message, 'color:#ff0000')
                this.setState({ loading: false });
            });
    }

    async getImpressionsByExamId(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/exam/getImpressionsByExamId?examId=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ impressions: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getImpressionsByExamId - ' + error.message);
                this.setState({ loading: false });
            });
    }

    hideToast() {
        setTimeout(() => {
            this.setState({
                toastMessage: '',
                toastErrorMessage: '',
                showToast: false,
                showToastError: false
            });
        }, 3500);
    }

    async deleteImpression(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var data = {
            Id: id
        }

        await fetch('api/Exam/DeleteExamImpression', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.getImpressionsByExamId(this.state.examId);
            })
            .catch((error) => {
                console.log('error in SavePreExam - ' + error);
                this.setState({ loading: false });
            });
    }

    async GetStudentById(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;

        await fetch(`api/Student/GetStudentById?Id=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    studentInfo: data,
                    IEP504Plan: data.ieP504Plan,
                    loading: false,
                    //examDate: data.examDate,
                    preferredName: data.preferredName,

                    ReceivedEyeExam: data.receivedEyeExam == true ? 'Yes' : data.receivedEyeExam == null ? undefined : 'No',
                    ReceivedEyeExamFromEyeThrive: data.receivedEyeExamFromEyeThrive == true ? 'Yes' : data.receivedEyeExamFromEyeThrive == null ? undefined : 'No',
                    PrescribedGlasses: data.prescribedGlasses == true ? 'Yes' : data.prescribedGlasses == null ? undefined : 'No',
                    PrescribedGlassesHowLongAgo: data.prescribedGlassesHowLongAgo,
                    ChildWearsGlassesNow: data.childWearsGlassesNow == true ? 'Yes' : data.childWearsGlassesNow == null ? undefined : 'No',
                    BlurryVision: data.blurryVision == true ? 'Yes' : data.blurryVision == null ? undefined : 'No',
                    SurgeriesOnEyes: data.surgeriesOnEyes == true ? 'Yes' : data.surgeriesOnEyes == null ? undefined : 'No',
                    ChildHaveDiabetes: data.childHaveDiabetes == true ? 'Yes' : data.childHaveDiabetes == null ? undefined : 'No',
                    ListOfMedications: data.listOfMedications,
                    ListOfAllergies: data.listOfAllergies,
                    MedicalConditions: data.medicalConditions,
                    EyeDiseaseHistory: data.eyeDiseaseHistory,
                    a1c: data.a1C,
                    diabetesYear: data.diabetesYear
                });
            })
            .catch(error => {
                console.log("error in GetStudentById - " + error);
                this.setState({ loading: false });
            });;
    }

    async GetExamGrid(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;

        await fetch('api/exam/getExamGrid?studentId=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    exams: data,
                });
            })
            .catch(error => {
                console.log("error in GetExamGrid - " + error);
                this.setState({ loading: false });
            });;
    }

    async openFile(id, filename) {
        const token = await authService.getAccessToken();
        await fetch('api/Student/GetStudentFile?id=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                if (filename && filename.includes('.pdf')) {
                    window.open(URL.createObjectURL(data));
                }
                else {
                    FileHelper.DownloadFile(data, filename);
                }
            })
    }

    async getImpressionsByKeyword() {
        if (this.state.searchWord && this.state.searchWord.length > 0) {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();
            await fetch('api/exam/GetImpressionsByKeyword?keyword=' + this.state.searchWord, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({
                        impressionsSearch: data,
                        showImpressionSearchGrid: true,
                        loading: false,
                        enableManualAddImpression: true
                    });

                })
                .catch((error) => {
                    console.log('error in getImpressionsByKeyword - ' + error.message);
                    this.setState({
                        loading: false
                    });
                });
        }
    }

    async addImpression(impressionId, impName) {
        if ((impressionId == undefined && this.state.impressionName.length > 0) || (impressionId != undefined) || (impName != undefined)) {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            var data = {
                ImpressionId: impressionId == undefined ? null : impressionId,
                ExamId: this.state.examId,
                Name: impressionId == undefined ? this.state.impressionName : null,
                ImpressionName: impName == undefined ? null : impName
            }

            await fetch('api/Exam/AddExamImpression', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    return response;
                })
                .then(async (data) => {
                    this.setState({ loading: false, quickImpression: 0 });
                    this.getImpressionsByExamId(this.state.examId);
                })
                .catch((error) => {
                    console.log('error in addImpression - ' + error);
                    this.setState({ loading: false });
                });
        }
    }

    async getExam(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/exam/getexam?examid=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                return response.json()
            })
            .then(async (data) => {
                this.setState({
                    examId: data.id,
                    examDate: data.examDate,
                    studentId: data.studentId,
                    siteId: data.siteId,
                    site: data.site,
                    HSRxODSphere: data.hsRxODSphere,
                    HSRxODCyl: data.hsRxODCyl,
                    HSRxODAxis: data.hsRxODAxis,
                    HSRxODAdd: data.hsRxODAdd,
                    HSRxOSSphere: data.hsRxOSSphere,
                    HSRxOSCyl: data.hsRxOSCyl,
                    HSRxOSAxis: data.hsRxOSAxis,
                    HSRxOSAdd: data.hsRxOSAdd,
                    AutorefractorODSphere: data.autorefractorODSphere,
                    AutorefractorODCyl: data.autorefractorODCyl,
                    AutorefractorODAxis: data.autorefractorODAxis,
                    AutorefractorOSSphere: data.autorefractorOSSphere,
                    AutorefractorOSCyl: data.autorefractorOSCyl,
                    AutorefractorOSAxis: data.autorefractorOSAxis,
                    Stereo3Shapes: data.stereo3Shapes,
                    Stereo8Randot: data.stereo8Randot,
                    Color12: data.color12,
                    DistancePDOD: data.distancePDOD,
                    DistancePDOS: data.distancePDOS,
                    DistanceTotal: data.distancePDTotal,
                    OcularHistory: data.ocularHistory,
                    Allergies: data.allergies,
                    MedicalHistory: data.medicalHistory,
                    PretestComments: data.preTestComments,
                    DistanceBlurOD: data.distanceBlurOD,
                    DistanceBlurOS: data.distanceBlurOS,
                    DistanceBlurOU: data.distanceBlurOU,
                    DistanceBlurConstant: data.distanceBlurConstant,
                    DistanceBlurIntermittent: data.distanceBlurIntermittent,
                    DistanceBlurNone: data.distanceBlurNone,
                    NearBlurOD: data.nearBlurOD,
                    NearBlurOS: data.nearBlurOS,
                    NearBlurOU: data.nearBlurOU,
                    NearBlurConstant: data.nearBlurConstant,
                    NearBlurIntermittent: data.nearBlurIntermittent,
                    NearBlurNone: data.nearBlurNone,
                    PupilsPERRL: data.pupilsPERRL,
                    PupilsAPD: data.pupilsAPD,
                    ChiefComplaintNotes: data.chiefComplaintNotes,
                    EnteringAcuitiesDistanceSCOD: data.enteringAcuitiesDistanceSCOD,
                    EnteringAcuitiesDistanceCCOD: data.enteringAcuitiesDistanceCCOD,
                    EnteringAcuitiesDistanceSCOS: data.enteringAcuitiesDistanceSCOS,
                    EnteringAcuitiesDistanceCCOS: data.enteringAcuitiesDistanceCCOS,
                    EnteringAcuitiesDistanceSCOU: data.enteringAcuitiesDistanceSCOU,
                    EnteringAcuitiesDistanceCCOU: data.enteringAcuitiesDistanceCCOU,
                    AcuitiesNotes: data.acuitiesNotes,
                    CTDistance: data.ctDistance,
                    PNCTTN: data.pncttn,
                    CTNear: data.ctNear,
                    NPCCM: data.npccm,
                    EnteringAcuitiesNearSCOD: data.enteringAcuitiesNearSCOD,
                    EnteringAcuitiesNearCCOD: data.enteringAcuitiesNearCCOD,
                    EnteringAcuitiesNearSCOS: data.enteringAcuitiesNearSCOS,
                    EnteringAcuitiesNearCCOS: data.enteringAcuitiesNearCCOS,
                    EnteringAcuitiesNearSCOU: data.enteringAcuitiesNearSCOU,
                    EnteringAcuitiesNearCCOU: data.enteringAcuitiesNearCCOU,
                    EOMs: data.eoMs,
                    EOMsNL: data.eoMsNL,
                    CVF: data.cvf,
                    CVFNL: data.cvfnl,
                    GridDropdown1: data.gridDropdown1,
                    GridDropdown2: data.gridDropdown2,
                    GridDropdown3: data.gridDropdown3,
                    GridDropdown4: data.gridDropdown4,
                    CVFOS: data.cvfos,
                    CVFNLOS: data.cvfnlos,
                    OSGridDropdown1: data.osGridDropdown1,
                    OSGridDropdown2: data.osGridDropdown2,
                    OSGridDropdown3: data.osGridDropdown3,
                    OSGridDropdown4: data.osGridDropdown4,
                    RetinoscopyODSphere: data.retinoscopyODSphere,
                    RetinoscopyODCyl: data.retinoscopyODCyl,
                    RetinoscopyODAxis: data.retinoscopyODAxis,
                    RetinoscopyOSSphere: data.retinoscopyOSSphere,
                    RetinoscopyOSCyl: data.retinoscopyOSCyl,
                    RetinoscopyOSAxis: data.retinoscopyOSAxis,
                    ManifestODSphere: data.manifestODSphere,
                    ManifestODCyl: data.manifestODCyl,
                    ManifestODAxis: data.manifestODAxis,
                    ManifestOSSphere: data.manifestOSSphere,
                    ManifestOSCyl: data.manifestOSCyl,
                    ManifestOSAxis: data.manifestOSAxis,
                    ManifestDistanceOU: data.manifestDistanceOU,
                    ManifestNearOU: data.manifestNearOU,
                    ManifestNotes: data.manifestNotes,
                    ManifestOD20: data.manifestOD20,
                    ManifestADD: data.manifestADD,
                    ManifestNearOU20: data.manifestNearOU20,
                    ManifestOS20: data.manifestOS20,
                    ManifestNRA: data.manifestNRA,
                    ManifestPRA: data.manifestPRA,
                    SlitLampODSelectAll: data.slitLampODSelectAll,
                    SlitLampOSSelectAll: data.slitLampOSSelectAll,
                    SlitLampODII: data.slitLampODII,
                    SlitLampOSII: data.slitLampOSII,
                    SlitLampODConj: data.slitLampODConj,
                    SlitLampOSConj: data.slitLampOSConj,
                    SlitLampODCornea: data.slitLampODCornea,
                    SlitLampOSCornea: data.slitLampOSCornea,
                    SlitLampODAC: data.slitLampODAC,
                    SlitLampOSAC: data.slitLampOSAC,
                    SlitLampODIris: data.slitLampODIris,
                    SlitLampOSIris: data.slitLampOSIris,
                    SlitLampODLens: data.slitLampODLens,
                    SlitLampOSLens: data.slitLampOSLens,
                    SlitLampNotes: data.slitLampNotes,
                    IOPOD: data.iopod,
                    TaODiCare: data.taODiCare,
                    IOPOS: data.iopos,
                    TaOSFT: data.taOSFT,
                    TaOSFTText: data.taOSFTText,
                    TaNotes: data.taNotes,
                    Dilation: data.dilation,
                    DilationDropsUsed: data.dilationDropsUsed,
                    DilationTime: data.dilationTime,
                    DilationNotes: data.dilationNotes,
                    DilationOD: data.dilationOD,
                    DilationOS: data.dilationOS,
                    DilationOU: data.dilationOU,
                    DilatedRefractionODSphere: data.dilatedRefractionODSphere,
                    DilatedRefractionODCyl: data.dilatedRefractionODCyl,
                    DilatedRefractionODAxis: data.dilatedRefractionODAxis,
                    DilatedRefractionOD20: data.dilatedRefractionOD20,
                    DilatedRefractionOSSphere: data.dilatedRefractionOSSphere,
                    DilatedRefractionOSCyl: data.dilatedRefractionOSCyl,
                    DilatedRefractionOSAxis: data.dilatedRefractionOSAxis,
                    DilatedRefractionOS20: data.dilatedRefractionOS20,
                    InternalDilated: data.internalDilated,
                    InternalODSelectAll: data.internalODSelectAll,
                    InternalOSSelectAll: data.internalOSSelectAll,
                    InternalUndilated: data.internalUndilated,
                    InternalODDisc: data.internalODDisc,
                    InternalOSDisc: data.internalOSDisc,
                    InternalBio: data.internalBio,
                    InternalODMacula: data.internalODMacula,
                    InternalOSMacula: data.internalOSMacula,
                    Internal20D: data.internal20D,
                    InternalODVessels: data.internalODVessels,
                    InternalOSVessels: data.internalOSVessels,
                    Internal90D: data.internal90D,
                    InternalODFundus: data.internalODFundus,
                    InternalOSFundus: data.internalOSFundus,
                    Internal78D: data.internal78D,
                    InternalODPeriphery: data.internalODPeriphery,
                    InternalOSPeriphery: data.internalOSPeriphery,
                    InternalPhotos: data.internalPhotos,
                    ODCD: data.odcd,
                    OSCD: data.oscd,
                    Referral: data.referral,
                    ReasonForReferral: data.reasonForReferral,
                    Provider: data.provider,
                    UrgentEmergency: data.urgentEmergency,
                    DiabetesCommunicationReport: data.diabetesCommunicationReport,
                    GlassesNeeded: data.glassesNeeded,
                    FinalSRxODSphere: data.finalSRxODSphere,
                    FinalSRxODCyl: data.finalSRxODCyl,
                    FinalSRxODAxis: data.finalSRxODAxis,
                    FinalSRxODAdd: data.finalSRxODAdd,
                    FinalSRxODPrism: data.finalSRxODPrism,
                    FinalSRxODBase: data.finalSRxODBase,
                    FinalSRxOSSphere: data.finalSRxOSSphere,
                    FinalSRxOSCyl: data.finalSRxOSCyl,
                    FinalSRxOSAxis: data.finalSRxOSAxis,
                    FinalSRxOSAdd: data.finalSRxOSAdd,
                    FinalSRxOSPrism: data.finalSRxOSPrism,
                    FinalSRxOSBase: data.finalSRxOSBase,
                    WearSchedule: data.wearSchedule,
                    CTNotes: data.ctNotes,
                    RetinoscopyNotes: data.retinoscopyNotes,
                    InternalNotes: data.internalNotes,
                    FinalNotes: data.finalNotes,
                    loading: false,
                    doctors: data.doctors,
                    DoctorUserId: data.userId,
                    TwoPairGlasses: data.twoPairGlasses,
                    InternalDirect: data.internalDirect
                }, async function () {
                    await authService.getUserId()
                        .then(async (id) => {
                            await userFunctions.IsInRole("Operational Support")
                                .then((result) => {
                                    if (result === true) {
                                        var index = this.state.doctors.findIndex(f => f.id?.toLowerCase() === data.userId?.toLowerCase());
                                        if (index !== -1) {
                                            this.setState({ doctorSig: this.state.doctors[index].signatureURL });
                                        }
                                    }
                                    else {
                                        var index = this.state.doctors.findIndex(f => f.id?.toLowerCase() === data.userId?.toLowerCase());
                                        if (index === -1) {
                                            index = this.state.doctors.findIndex(f => f.id?.toLowerCase() === id?.toLowerCase());
                                        }

                                        //alert(index)

                                        //this.setState({ DoctorUserId: id?.toLowerCase(), doctorSig: this.state.doctors[index].signatureURL });

                                        var doctors = [];
                                        //var index = this.state.doctors.findIndex(f => f.id?.toLowerCase() == id?.toLowerCase());
                                        var doctor = this.state.doctors[index];
                                        doctors.push(doctor);
                                        this.setState({ doctors: doctors }, function () {
                                            this.setState({ DoctorUserId: this.state.doctors[0].id, doctorSig: this.state.doctors[0].signatureURL });
                                        });

                                        if (data.userId != null && data.userId?.toLowerCase() !== id?.toLowerCase()) {
                                            this.setState({ disabled: true });
                                        }
                                    }
                                });
                        });
                });
            })
            .catch((error) => {
                console.log('error in getImpressionsByKeyword - ' + error.message);
                this.setState({
                    loading: false
                });
            });
    }

    async getDropdownValues() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Exam/GetDropdownValues', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then(async (data) => {
                var sphereDefaultValueIndex = data.examSphere.findIndex(f => f.name == 'plano');
                var sphereDefaultValue = '';
                var iopDefaultValue = '';
                var ids = [];
                if (sphereDefaultValueIndex != -1) {
                    sphereDefaultValue = data.examSphere[sphereDefaultValueIndex].id;
                    ids.push(sphereDefaultValue);
                }

                var iopDefaultValueIndex = data.examIOP.findIndex(f => f.name == '20');
                if (iopDefaultValueIndex != -1) {
                    iopDefaultValue = data.examIOP[iopDefaultValueIndex].id;
                    ids.push(iopDefaultValue);
                }

                this.setState({
                    examAcuities: data.examAcuities,
                    examAdd: data.examAdd,
                    examBase: data.examBase,
                    examCD: data.examCD,
                    examCTDistance: data.examCTDistance,
                    examCTNear: data.examCTNear,
                    examCyl: data.examCyl,
                    examDropsUsed: data.examDropsUsed,
                    examIOP: data.examIOP,
                    examNRA: data.examNRA,
                    examPRA: data.examPRA,
                    examPrism: data.examPrism,
                    examProvider: data.examProvider,
                    examSphere: data.examSphere,
                    examWearSchedule: data.examWearSchedule,
                    loading: false
                }, function () {
                    this.loadPreTestDefaults(sphereDefaultValue);
                    this.loadExamDefaults(ids);
                });

            })
            .catch((error) => {
                console.log('error in getDropdownValues - ' + error.message);
                this.setState({ loading: false });
            });
    }

    loadPreTestDefaults(id) {
        //this.setState({
        //    HSRxODSphere: id,
        //    HSRxOSSphere: id,
        //    AutorefractorODSphere: id,
        //    AutorefractorOSSphere: id,
        //});
    }

    loadExamDefaults(ids) {
        //this.setState({
        //    RetinoscopyODSphere: ids[0],
        //    RetinoscopyOSSphere: ids[0],
        //    ManifestODSphere: ids[0],
        //    ManifestOSSphere: ids[0],
        //    DilatedRefractionODSphere: ids[0],
        //    DilatedRefractionOSSphere: ids[0],
        //    FinalSRxODSphere: ids[0],
        //    FinalSRxOSSphere: ids[0],
        //    IOPOD: ids[1],
        //    IOPOS: ids[1]
        //});
    }

    async deleteFrame(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/Lab/DeleteFrame?labFrameId=' + id + "&adjustInventory=false", {
            method: 'POST',
            body: {},
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then(async (data) => {
                await this.getLabFrames(this.state.studentId);
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in deleteFrame - ' + error);
                this.setState({ loading: false });
            });
    }

    async getLabFrames(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Lab/GetLabFrames?studentId=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    labFrames: data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log('error in getLabData - ' + error.message);
                this.setState({ loading: false });
            });
    }
}