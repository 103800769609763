import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Table, Row, Col, Input, Button, Spinner, Toast, ToastBody } from 'reactstrap';
import Modal from 'react-modal';
import userFunctions from './UserFunctions';

export class StudentForms extends Component {
    static displayName = StudentForms.name;
    constructor(props) {
        super(props);

        this.state = {
            studentsNoMatch: [],
            studentsNoMatchCopy: [],
            replacementsNoMatch: [],
            replacementsNoMatchCopy: [],
            studentSearch: [],
            students: [],
            loading: false,
            showModal: false,
            showToast: false,
            toastMessage: '',
            toastErrorMessage: '',
            showToastError: false,
            action: '',
            id1: '',
            id2: ''
        }
    }

    componentDidMount() {
        this.getFormsNoMatch();
        this.getReplacementFormsNoMatch();
        this.getGrades();
        this.getDistricts();
    }

    isLoading(isLoading) {
        this.setState({ loading: isLoading });
    }

    hideToast() {
        setTimeout(() => {
            this.setState({
                toastMessage: '',
                toastErrorMessage: '',
                showToast: false,
                showToastError: false,
                isReplacement: false
            });
        }, 3500);
    }

    async getFormsNoMatch() {
        this.isLoading(true);
        const token = await authService.getAccessToken();

        await fetch('api/StudentForms/GetFormsNoMatch', {
            method: 'GET',
            body: null,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ studentsNoMatch: data, studentsNoMatchCopy: data, loading: false });
                this.isLoading(false);
            })
            .catch((error) => {
                console.log('error in getFormsNoMatch');
                this.isLoading(false);
            });
    }

    async getReplacementFormsNoMatch() {
        this.isLoading(true);
        const token = await authService.getAccessToken();

        await fetch('api/StudentForms/GetReplacementFormsNoMatch', {
            method: 'GET',
            body: null,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ replacementsNoMatch: data, replacementsNoMatchCopy: data, loading: false });
                this.isLoading(false);
            })
            .catch((error) => {
                console.log('error in getReplacementFormsNoMatch');
                this.isLoading(false);
            });
    }

    async searchStudents(dataObject) {
        this.setState({ students: [] });
        sessionStorage.removeItem("studentSearch");

        const token = await authService.getAccessToken();
        var dto = [];

        if (dataObject != undefined && dataObject != null) {
            dto = dataObject;
            //sessionStorage.removeItem("studentSearch");
        }
        else {
            dto = {
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 ? null : this.state.siteId,
                GradeId: this.state.gradeId == userFunctions.GuidEmpty() || this.state.gradeId == 0 ? null : this.state.gradeId,
                DateOfBirth: this.state.dateOfBirth,
                QueryString: this.state.search ?? "",
                SchoolOther: this.state.schoolOther ?? null,
                DistrictOther: this.state.districtOther ?? null
            }
        }

        this.setState({ loading: true });

        await fetch('api/Student/SearchStudents', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (data.length > 0) {
                    this.setState({
                        students: data,
                        noResults: data.length === 0 ? true : false,
                        showDistrictOther: data.length === 0 ? false : data[0].showDistrictOther
                    }, function () {
                        this.setState({ loading: false });
                        sessionStorage.setItem("studentSearch", JSON.stringify(dto));
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        noResults: data.length === 0 ? true : false
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                alert(error);
            });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.students;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }

    async getDistricts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetDistricts', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ districtList: data, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getDistricts - ' + error.message);
            });
    }

    setDistrictOther(_this, showDistrictOther) {
        if (showDistrictOther) {
            var id = "";
            _this.state.siteList.find(function (item, i) {
                if (item.name == 'Other') {
                    id = item.id;
                }
            });
            this.setState({ siteId: id, showDistrictOther: showDistrictOther });
        }
    }

    async getGrades() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetGrades', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ gradeList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getGrades - ' + error.message);
            });
    }

    async getSitesByDistrictId(districtId, showDistrictOther) {
        var _this = this;
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSitesByDistrictId?id=' + districtId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ siteList: data }, function () {
                    if (showDistrictOther) {
                        this.setDistrictOther(_this, showDistrictOther);
                    }
                    else {
                        _this.setState({ showDistrictOther: showDistrictOther });
                    }
                });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getSitesByDistrictId - ' + error.message, 'color:#ff0000')
            });
    }

    deleteStudentNoMatchForm(id) {
        this.setState({ action: "delete", modalTitle: "Delete the Consent Form?", showModal: true, id1: id, isReplacement: false })
    }

    deleteReplacementNoMatchForm(id) {
        this.setState({ action: "delete", modalTitle: "Delete the Replacement Form?", showModal: true, id1: id, isReplacement: true })
    }

    async delete(id) {
        this.isLoading(true);
        const token = await authService.getAccessToken();
        var data = {
            NoMatchId: id
        }

        await fetch(this.state.isReplacement ? 'api/StudentForms/RemoveReplacementFormNoMatch' : 'api/StudentForms/RemoveStudentNoMatch', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (data) {
                    this.setState({ id1: '', toastMessage: this.state.isReplacement ? 'Replacement Form deleted successfully' : 'Consent Form deleted successfully', showToast: true, showModal: false });
                    this.isLoading(false);
                    this.getFormsNoMatch();
                    this.getReplacementFormsNoMatch();
                }
                else {
                    this.setState({ id1: '', toastErrorMessage: this.state.isReplacement ? 'There was an error deleting the Replacement Form' : 'There was an error deleting the Consent Form', showToastError: true, showModal: false });
                    this.isLoading(false);
                }
                this.hideToast();
            })
            .catch((error) => {
                console.log('error in delete');
                this.isLoading(false);
            });
    }

    AddStudentNoMatchForm(id) {
        this.setState({ id1: id, modalTitle: "Add a new student using information from the Consent Form?", action: 'add', showModal: true });
    }

    onMatchFormToStudent(id) {
        this.setState({ id2: id, modalTitle: this.state.isReplacement === undefined || !this.state.isReplacement ? "Match this student with the Consent Form?" : "Match this student with the Replacement Form?", action: 'match', showModal: true });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    matchStudent(id, dob) {
        this.setState({ id1: id, showStudentSearch: true, dateOfBirth: this.formatDate(dob) });
    }

    matchReplacementToStudent(id, dob) {
        this.setState({ id1: id, showStudentSearch: true, dateOfBirth: this.formatDate(dob), isReplacement: true });
    }

    async updateStudentWithConsentFormData(id1, id2) {
        var data;

        this.isLoading(true);
        const token = await authService.getAccessToken();

        if (this.state.isReplacement) {
            data = {
                NoMatchId: id1,
                StudentId: id2
            }

            await fetch('api/StudentForms/MatchStudentReplacementFromNoMatch', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data) {
                        this.setState({ id1: '', id2: '', students: [], showStudentSearch: false, toastMessage: 'Replacement data updated successfully', showToast: true, showModal: false, isReplacement: undefined });
                        this.isLoading(false);
                        this.getReplacementFormsNoMatch();
                    }
                    else {
                        this.setState({ id1: '', id2: '', students: [], showStudentSearch: false, toastErrorMessage: 'There was a problem updating the replacement data', showToastError: true, showModal: false, isReplacement: undefined });
                        this.isLoading(false);
                    }
                    this.hideToast();
                })
                .catch((error) => {
                    console.log('error in delete');
                    this.isLoading(false);
                });
        }
        else {
            data = {
                NoMatchId: id1,
                StudentId: id2
            }

            await fetch('api/StudentForms/MatchStudentFromNoMatch', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data) {
                        this.setState({ id1: '', id2: '', students: [], showStudentSearch: false, toastMessage: 'Student updated successfully', showToast: true, showModal: false });
                        this.isLoading(false);
                        this.getFormsNoMatch();
                    }
                    else {
                        this.setState({ id1: '', id2: '', students: [], showStudentSearch: false, toastErrorMessage: 'There was a problem updating the student', showToastError: true, showModal: false });
                        this.isLoading(false);
                    }
                    this.hideToast();
                })
                .catch((error) => {
                    console.log('error in delete');
                    this.isLoading(false);
                });
        }
    }

    async addStudent(id1) {
        this.isLoading(true);
        const token = await authService.getAccessToken();
        var data = {
            NoMatchId: id1
        }

        await fetch('api/StudentForms/AddStudentFromFormsNoMatch', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (data) {
                    this.setState({ id1: '', id2: '', students: [], showStudentSearch: false, toastMessage: 'Student added successfully', showToast: true, showModal: false });
                    this.isLoading(false);
                    this.getFormsNoMatch();
                }
                else {
                    this.setState({ id1: '', id2: '', students: [], showStudentSearch: false, toastErrorMessage: 'There was a problem adding the student', showToastError: true, showModal: false });
                    this.isLoading(false);
                }
                this.hideToast();
            })
            .catch((error) => {
                console.log('error in delete');
                this.isLoading(false);
            });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchStudents();
        }
    }

    render() {
        const renderItems = this.state.students?.map((student, i) => {
            return (
                <tr key={i} style={{ cursor: 'pointer' }}>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.lastName}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.firstName}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.dateOfBirth}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.site}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.schoolOther}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.district}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.districtOther}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.grade}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.teacher}</td>
                    <td onClick={() => this.onMatchFormToStudent(student.id)}>{student.mvcVisitLocation}</td>
                </tr>
            )
        });
        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                {this.state.showToastError ?
                    <Toast className="toast-error">
                        <ToastBody className="toast-body">
                            {this.state.toastErrorMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                <h2>Student Forms</h2>
                <br />
                <p>
                    <h5>Consent forms not matched to a student</h5>
                    <div style={{ width: '30%', marginBottom: '5px' }}>
                        <Input
                            type='text'
                            onChange={(e) => {
                                var search = e.target.value;
                                var _data = this.state.studentsNoMatchCopy;

                                this.setState({ studentsNoMatch: [] });

                                if (search === "") {
                                    this.setState({ studentsNoMatch: _data });
                                }
                                else if (search !== undefined && search !== null) {
                                    var _filteredData = _data.filter(item => item.firstName?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.dateOfBirth?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.school?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.district?.toLowerCase().includes(search.toLowerCase()));

                                    this.setState({ studentsNoMatch: _filteredData });
                                }
                            }}
                            placeholder='Search by First Name, Last Name, Date of Birth, School or District'
                        />
                    </div>
                    <div style={{ width: 'fit-content', maxHeight: '400px', overflowY: 'auto' }}>
                        <table className={"studentForms"}>
                            <thead>
                                <tr>
                                    <th colSpan={3}></th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Date Of Birth</th>
                                    <th>School</th>
                                    <th>District</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.studentsNoMatch && this.state.studentsNoMatch.length > 0 ?
                                    this.state.studentsNoMatch.map((item, i) =>
                                        <tr key={i}>
                                            <td>
                                                <Button
                                                    color='primary'
                                                    onClick={() => this.deleteStudentNoMatchForm(item.id)}
                                                    className={"grid-btn"}
                                                    disabled={this.state.loading}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    color='primary'
                                                    onClick={() => this.AddStudentNoMatchForm(item.id)}
                                                    className={"grid-btn"}
                                                    disabled={this.state.loading}
                                                >
                                                    Add
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    color='primary'
                                                    onClick={() => this.matchStudent(item.id, item.dateOfBirth)}
                                                    className={"grid-btn"}
                                                    disabled={this.state.loading}
                                                >
                                                    Match
                                                </Button>
                                            </td>
                                            <td>{item.firstName}</td>
                                            <td>{item.lastName}</td>
                                            <td>{item.dateOfBirth}</td>
                                            <td>{item.school}</td>
                                            <td>{item.district}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={8} key={0}>
                                            <label>No data available</label>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </p>
                <br />
                <p>
                    <h5>Replacement forms not matched to a student</h5>
                    <div style={{ width: '30%', marginBottom: '5px' }}>
                        <Input
                            type='text'
                            onChange={(e) => {
                                var search = e.target.value;
                                var _data = this.state.replacementsNoMatchCopy;

                                this.setState({ replacementsNoMatch: [] });

                                if (search === "") {
                                    this.setState({ replacementsNoMatch: _data });
                                }
                                else if (search !== undefined && search !== null) {
                                    var _filteredData = _data.filter(item => item.firstName?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.dateOfBirth?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.school?.toLowerCase().includes(search.toLowerCase()) ||
                                        item.district?.toLowerCase().includes(search.toLowerCase()));
                                    this.setState({ replacementsNoMatch: _filteredData });
                                }
                            }}
                            placeholder='Search by First Name, Last Name, Date of Birth, School or District'
                        />
                    </div>
                    <div style={{ width: 'fit-content', maxHeight: '400px', overflowY: 'auto' }}>
                        <table className={"studentForms"}>
                            <thead>
                                <tr>
                                    <th colSpan={2}></th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Date Of Birth</th>
                                    <th>School</th>
                                    <th>District</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.replacementsNoMatch && this.state.replacementsNoMatch.length > 0 ?
                                    this.state.replacementsNoMatch.map((item, i) =>
                                        <tr key={i}>
                                            <td>
                                                <Button
                                                    color='primary'
                                                    onClick={() => this.deleteReplacementNoMatchForm(item.id)}
                                                    className={"grid-btn"}
                                                    disabled={this.state.loading}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    color='primary'
                                                    onClick={() => this.matchReplacementToStudent(item.id, item.dateOfBirth)}
                                                    className={"grid-btn"}
                                                    disabled={this.state.loading}
                                                >
                                                    Match
                                                </Button>
                                            </td>
                                            <td>{item.firstName}</td>
                                            <td>{item.lastName}</td>
                                            <td>{item.dateOfBirth}</td>
                                            <td>{item.school}</td>
                                            <td>{item.district}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={8} key={0}>
                                            <label>No data available</label>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </p>

                <p>
                    {this.state.showStudentSearch ?
                        <div style={{ padding: '10px', borderRadius: '10px', border: '1px solid #ddd', marginTop: '50px' }}>
                            <Container>
                                <Row>
                                    <Col>
                                        <h5>Student Search</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className={'row-label-input'}>
                                            <label className="row-label-0">District</label>
                                            <select className="form-control" value={this.state.districtId}
                                                onChange={(e) => {
                                                    this.setState({ districtId: e.target.value });
                                                    if (e.target.value == 0) {
                                                        this.setState({ siteList: [] });
                                                        this.setState({
                                                            showDistrictOther: false,
                                                            districtId: undefined,
                                                            siteId: undefined,
                                                            districtOther: undefined,
                                                            schoolOther: undefined
                                                        })
                                                    }
                                                    else {
                                                        this.getSitesByDistrictId(e.target.value, e.target.options[e.target.selectedIndex].text == 'Other' ? true : false);
                                                    }
                                                }}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.districtList && this.state.districtList.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={'row-label-input'}>
                                            <label className="row-label-0">School</label>
                                            <select className="form-control" value={this.state.siteId}
                                                disabled={this.state.showDistrictOther}
                                                onChange={(e) => {
                                                    this.setState({ siteId: e.target.value == 0 ? undefined : e.target.value });
                                                }}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.siteList && this.state.siteList.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={'row-label-input'}>
                                            <label className="row-label-0">Grade</label>
                                            <select className="form-control" value={this.state.gradeId}
                                                onChange={(e) => {
                                                    this.setState({ gradeId: e.target.value == 0 ? undefined : e.target.value });
                                                }}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.gradeList && this.state.gradeList.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={'row-label-input'}>
                                            <label className="row-label-0" style={{ width: 'fit-content' }}>Date Of Birth</label>
                                            <Input
                                                type='date'
                                                onChange={(e) => this.setState({ dateOfBirth: e.target.value })}
                                                value={this.state.dateOfBirth}
                                                placeholder='Date Of Birth'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {this.state.showDistrictOther ?
                                    <Row>
                                        <Col>
                                            <div style={{ display: 'flex', width: 'auto', marginTop: '7px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <label style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>District (Other)</label>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ districtOther: e.target.value });
                                                    }}
                                                    name='DistrictOther'
                                                    maxLength={100}
                                                    placeholder={'District (Other)'}
                                                    value={this.state.districtOther ?? ''}
                                                    style={this.state.showDistrictOther ? { display: 'inline' } : { display: 'none' }}
                                                    autoFocus={this.state.showDistrictOther}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div style={{ display: 'flex', width: 'auto', marginTop: '7px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <label style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>School (Other)</label>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ schoolOther: e.target.value });
                                                    }}
                                                    name='schoolOther'
                                                    maxLength={100}
                                                    placeholder={'School (Other)'}
                                                    value={this.state.schoolOther ?? ''}
                                                    style={this.state.showDistrictOther ? { display: 'inline' } : { display: 'none' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    : null}
                                <Row style={{ marginTop: '20px' }}>
                                    <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                                        <Input
                                            type='text'
                                            onChange={(e) => this.setState({ search: e.target.value })}
                                            value={this.state.search}
                                            onKeyPress={this.handleKeyPress}
                                            placeholder='Search Student First Name or Last Name'
                                        />
                                    </Col>
                                    <Col xl='2' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Button
                                            color='primary'
                                            onClick={() => this.searchStudents()}
                                            style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content' }}
                                            disabled={this.state.loading ? true : false}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                    <Col xl='4' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                        <div style={{ maxHeight: '58vh', height: '100%', maxWidth: '80vw', width: '100%', overflow: 'auto auto' }}>
                                            <Table responsive className="gridTable">
                                                <thead >
                                                    <tr>
                                                        <th>
                                                            Last Name
                                                        </th>
                                                        <th>
                                                            First Name
                                                        </th>
                                                        <th>
                                                            Date Of Birth
                                                        </th>
                                                        <th>
                                                            School
                                                        </th>
                                                        <th>
                                                            School Other
                                                        </th>
                                                        <th>
                                                            District
                                                        </th>
                                                        <th>
                                                            District Other
                                                        </th>
                                                        <th>
                                                            Grade
                                                        </th>
                                                        <th>
                                                            Teacher
                                                        </th>
                                                        <th>
                                                            MVC Visit Location
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!this.state.noResults ?
                                                        <>
                                                            {renderItems}
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan={13} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                                No Results Found
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        : null}
                </p>
                <div>
                    <Modal
                        isOpen={this.state.showModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showSaveModal: false })}
                        className="et-modal"
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>{this.state.modalTitle}</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', background: '#ffffff00' }}>
                            <table style={{ marginTop: '10px', marginBottom: '10px', background: '#ffffff00' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <button
                                                color='primary'
                                                className="btn btn-primary"
                                                style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                                                onClick={() => { this.setState({ showModal: false }) }}>
                                                No
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                color='primary'
                                                className="btn btn-primary"
                                                style={{ whiteSpace: 'nowrap' }}
                                                onClick={() => {
                                                    if (this.state.action === 'delete') {
                                                        this.delete(this.state.id1);
                                                    }
                                                    else if (this.state.action === 'add') {
                                                        this.addStudent(this.state.id1);
                                                    }
                                                    else if (this.state.action === 'match') {
                                                        this.updateStudentWithConsentFormData(this.state.id1, this.state.id2);
                                                    }
                                                }}>
                                                Yes
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}